import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useLaproscopyPhotosServices = () => {
  const axiosAuth = useAxiosAuth();

  const addLaproscopyReportPhoto = async (id, fd) => {
    try {
      const response = await axiosAuth.post(
        `/api/LaproscopyPhotos/upload-laproscopy-photo?laproscopyId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getLaproscopyPhotoByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/LaproscopyPhotos/by-laproscopy-id?laproscopyId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteLaproscopyPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/LaproscopyPhotos?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addLaproscopyReportPhoto,
    getLaproscopyPhotoByScanReportId,
    deleteLaproscopyPhoto,
  };
};
export default useLaproscopyPhotosServices;
