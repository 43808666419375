import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useMenstrualHistory = () => {
  const axiosAuth = useAxiosAuth();

  const getMenstrualHistoryById = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientMenstrualHistories/by-Patient-id?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const addMenstrualHistory = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/PatientMenstrualHistories`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateMenstrualHistory = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/PatientMenstrualHistories?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteMenstrualHistory = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/PatientMenstrualHistories?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getMenstrualHistoryById,
    addMenstrualHistory,
    updateMenstrualHistory,
    deleteMenstrualHistory,
  };
};

export default useMenstrualHistory;
