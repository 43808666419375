import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useAntenatalRecordsService = () => {
  const axiosAuth = useAxiosAuth();

  const addAntenatalRecord = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/PatientAntenatalRecords`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAntenatalRecordById = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientAntenatalRecords?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAntenalRecordByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientAntenatalRecords/by-patient-id?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateAntenatalRecord = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/PatientAntenatalRecords?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteAntenatalRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/PatientAntenatalRecords?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addAntenatalRecord,
    getAntenatalRecordById,
    getAntenalRecordByPatientId,
    updateAntenatalRecord,
    deleteAntenatalRecord,
  };
};

export default useAntenatalRecordsService;
