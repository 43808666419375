import { Grid, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportCard from "../ReportCard";
import NoReport from "ui-component/formcomponent/NoReport";
import useSonographyService from "services/useSonographyReport";
import { useSelector } from "react-redux";
import Comparison from "../Comparison";
import FirstTrimester from "./FirstTrimester";
import SecoundTrimester from "./SecoundTrimester";
import ThirdTrimester from "./ThirdTrimester";
import { useSearchParams } from "react-router-dom";
const MobileReport = () => {
  const { role, profileId } = useSelector((state) => state.userReducer);
  const [searchParams] = useSearchParams();
  const [firstTrimesterReport, setFirstTrimesterReport] = useState(1);
  const [secoundTrimesterReport, setSecoundTrimesterReport] = useState(3);
  const [thirdTrimesterReport, setThirdTrimesterReport] = useState(5);

  const [firstTrimesterData, setFirstTrimesterData] = useState();
  const [secoundTrimesterData, setSecoundTrimesterData] = useState();
  const [thirdTrimesterData, setThirdTrimesterData] = useState();

  const { getSonographyByPatientId } = useSonographyService();
  const loadData = async () => {
    try {
      let id = 0;
      if (role == 7) {
        id = profileId;
      } else {
        id = searchParams.get("Id");
      }
      const resultsonography = await getSonographyByPatientId(
        id,
        firstTrimesterReport
      );

      setFirstTrimesterData(resultsonography.data?.sonographies);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadData();
  }, [firstTrimesterReport]);
  const loadSeoundTrimesterData = async () => {
    try {
      let id = 0;
      if (role == 7) {
        id = profileId;
      } else {
        id = searchParams.get("Id");
      }
      const resultsonography = await getSonographyByPatientId(
        id,
        secoundTrimesterReport
      );

      setSecoundTrimesterData(resultsonography.data?.sonographies);
    } catch (err) {
      console.log(err);
    }
  };
  const loadThirdTrimesterData = async () => {
    try {
      let id = 0;
      if (role == 7) {
        id = profileId;
      } else {
        id = searchParams.get("Id");
      }
      const resultsonography = await getSonographyByPatientId(
        id,
        thirdTrimesterReport
      );

      setThirdTrimesterData(resultsonography.data?.sonographies);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadSeoundTrimesterData();
  }, [secoundTrimesterReport]);
  useEffect(() => {
    loadThirdTrimesterData();
  }, [thirdTrimesterReport]);
  return (
    <>
      <Grid container mt={2}>
        <FirstTrimester
          firstTrimesterData={firstTrimesterData}
          setFirstTrimesterReport={setFirstTrimesterReport}
          firstTrimesterReport={firstTrimesterReport}
          loadData={loadData}
        />
        <SecoundTrimester
          secoundTrimesterData={secoundTrimesterData}
          setSecoundTrimesterReport={setSecoundTrimesterReport}
          secoundTrimesterReport={secoundTrimesterReport}
          loadSeoundTrimesterData={loadSeoundTrimesterData}
        />
        <ThirdTrimester
          thirdTrimesterReport={thirdTrimesterReport}
          setThirdTrimesterReport={setThirdTrimesterReport}
          thirdTrimesterData={thirdTrimesterData}
          loadThirdTrimesterData={loadThirdTrimesterData}
        />
      </Grid>
      <Grid xs={12}>
        <Typography variant="h4" color="primary" mt={2}>
          Comparision
        </Typography>
        <Comparison
          patientId={role == 7 ? profileId : searchParams.get("Id")}
        />
      </Grid>
    </>
  );
};

export default MobileReport;
