// assets
import { IconUsers, IconBed } from "@tabler/icons";

// constant
const icons = {
  IconUsers,
  IconBed,
};

// ==============================|| IPDSTAFF MENU ITEMS ||============================== //

const pharmacyManagementMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "pharmacy-management",
        title: "Pharmacy Management",
        type: "item",
        url: "/pharmacy-management",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },
];

export default pharmacyManagementMenuItems;
