import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useLummellaPhotosServices = () => {
  const axiosAuth = useAxiosAuth();

  const addLummellaReportPhoto = async (id, fd) => {
    try {
      const response = await axiosAuth.post(
        `/api/LummellaPhotos/upload-lummella-photo?lummellaId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getLummellaPhotoByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/LummellaPhotos/by-lummella-id?lummellaId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteLummellaPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/LummellaPhotos?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addLummellaReportPhoto,
    getLummellaPhotoByScanReportId,
    deleteLummellaPhoto,
  };
};
export default useLummellaPhotosServices;
