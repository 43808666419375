import React from 'react';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { handleResponse, handleError } from './response';

const useObstetricHistoryService = () => {
    const axiosAuth = useAxiosAuth();

    const addObstetricHistoryRecord = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/PatientObsetricHistories`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/PatientObsetricHistories?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getByAntenatalRecordId = async (antenatalRecordId) => {
        try {
            const response = await axiosAuth.get(
                `/api/PatientObsetricHistories/list-byPatientAntenatalRecord?patientAntenatalRecordId=${antenatalRecordId}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateObstetricHistoryRecord = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/PatientObsetricHistories?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteObstetricHistoryRecord = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/PatientObsetricHistories?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        addObstetricHistoryRecord,
        getById,
        getByAntenatalRecordId,
        updateObstetricHistoryRecord,
        deleteObstetricHistoryRecord
    };
};

export default useObstetricHistoryService;
