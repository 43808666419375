import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAntenatalRecordsService from "services/useAntenatalRecordsService";
import useObstetricHistoryService from "services/useObstetricHistoryService";
import usePatientAbortionHistoryService from "services/usePatientAbortionHistoryService";
import { convertDateToLocal } from "utils/DateOperations";

const InvestigationAntenatalRecord = ({ patientData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:770px)");
  const getCurrentWeek = (date) => {
    const today = new Date();
    const lmp = new Date(date);

    const timeDiff = today - lmp;
    const totalDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;

    return `${weeks} Weeks ${days} Days`;
  };

  const [antenalRecord, setAntenalRecord] = useState();
  const [records, setRecords] = useState();
  const [abortionrecord, setAbortionRecord] = useState();

  const { getAntenalRecordByPatientId } = useAntenatalRecordsService();
  const { getByAntenatalRecordId } = useObstetricHistoryService();
  const { getAbortionHistoryByAntenatalRecordId } =
    usePatientAbortionHistoryService();

  const loadAntenalRecords = async () => {
    if (patientData) {
      const response = await getAntenalRecordByPatientId(patientData?.id);
      console.log("ANTENAL RECORSSSS", response);
      setAntenalRecord(response.data);
    }
  };

  const getData = (skip) => {
    if (antenalRecord)
      getByAntenatalRecordId(antenalRecord?.id)
        .then((response) => {
          if (response.status === 200) {
            setRecords(response.data.patientObsetricHistories);
          }
        })
        .catch((err) => console.log(err));
  };

  const gatherAbortionData = () => {
    if (antenalRecord)
      getAbortionHistoryByAntenatalRecordId(antenalRecord?.id).then(
        (response) => {
          if (response.status === 200) {
            setAbortionRecord(response.data.patientAbortionHistories);
          }
        }
      );
  };

  useEffect(() => {
    loadAntenalRecords();
  }, []);

  useEffect(() => {
    getData();
    gatherAbortionData();
  }, [antenalRecord]);
  return (
    <>
      {antenalRecord != undefined ? (
        <>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Antenatal Records
          </Typography>
          <Card
            variant="outlined"
            sx={{ padding: 2, backgroundColor: "#FFFAF0" }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography
                  variant="h4"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Important Features:
                </Typography>
                <Typography>{antenalRecord.importantFeatures}</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography
                  variant="h4"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Suggested Management:
                </Typography>
                <Typography>{antenalRecord.suggestedManagement}</Typography>
              </Grid>
              <Grid item sm={3} xs={6}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Married Since:
                </Typography>
                <Typography>
                  {antenalRecord.yearOfMarriage != 0
                    ? `${antenalRecord.yearOfMarriage}year, `
                    : ""}{" "}
                  {antenalRecord.monthOfMarriage != 0
                    ? ` ${antenalRecord.monthOfMarriage}month, `
                    : ""}
                  {antenalRecord.dayOfMarriage != 0
                    ? ` ${antenalRecord.dayOfMarriage}days `
                    : ""}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Menstrul Histoty:
                </Typography>
                <Typography>{antenalRecord.menstrulHistory}</Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Consanguinity:
                </Typography>
                <Typography>{antenalRecord.consanguinity}</Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Contraception:
                </Typography>
                <Typography>{antenalRecord.contraception}</Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  TT First Dose Taken:
                </Typography>
                <Typography>
                  {antenalRecord.ttDose1Given == true ? "Yes" : "No"}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  TT First Dose Date:
                </Typography>
                <Typography>
                  {antenalRecord.ttDose1Date != "0001-01-01T00:00:00"
                    ? convertDateToLocal(antenalRecord.ttDose1Date)
                    : "---"}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  TT Second Dose Taken:
                </Typography>
                <Typography>
                  {antenalRecord.ttDose2Given == true ? "Yes" : "No"}
                </Typography>
              </Grid>
              {antenalRecord.ttDose2Given == true ? (
                <Grid item sm={3} xs={12}>
                  <Typography
                    variant="h5"
                    color={theme.palette.grey[700]}
                    sx={{ marginBottom: 1 }}
                  >
                    TT Second Dose Date:
                  </Typography>
                  <Typography>
                    {antenalRecord.ttDose2Date != "0001-01-01T00:00:00"
                      ? convertDateToLocal(antenalRecord.ttDose2Date)
                      : "---"}
                  </Typography>
                </Grid>
              ) : null}

              <Grid item sm={3} xs={6}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  LMP:
                </Typography>

                <Typography>
                  {antenalRecord.lmp != "0001-01-01T00:00:00"
                    ? convertDateToLocal(antenalRecord.lmp)
                    : "---"}
                </Typography>
              </Grid>
              <Grid
                item
                sm={3}
                xs={10}
                sx={{
                  display:
                    antenalRecord.lmp == "0001-01-01T00:00:00"
                      ? "none"
                      : "block",
                }}
              >
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Current Week:
                </Typography>
                <Typography>
                  {getCurrentWeek(antenalRecord.lmp) == "105537 Weeks 0 Days"
                    ? "NA"
                    : getCurrentWeek(antenalRecord.lmp)}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={6}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  EDD:
                </Typography>
                <Typography>
                  {" "}
                  {antenalRecord.edd != "0001-01-01T00:00:00"
                    ? convertDateToLocal(antenalRecord.edd)
                    : "---"}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={6}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Corrected EDD:
                </Typography>
                {antenalRecord.correctedEDD != "0001-01-01T00:00:00" ? (
                  <Typography>
                    {convertDateToLocal(antenalRecord.correctedEDD)}
                  </Typography>
                ) : (
                  <Typography>---</Typography>
                )}
              </Grid>

              <Grid
                item
                sm={3}
                xs={12}
                sx={{ display: { sm: "none", xs: "block" } }}
              >
                <Divider />
              </Grid>

              <Grid item sm={12} sx={{ display: { sm: "block", xs: "none" } }}>
                <Typography variant="h4">Obstetric Formula</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                sx={{ display: { sm: "none", xs: "block" } }}
              >
                <Typography variant="h5">Obstetric Formula</Typography>
              </Grid>

              <Grid item sm={3} xs={3}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Gravida:
                </Typography>
                <Typography>{antenalRecord.gravida}</Typography>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Para:
                </Typography>
                <Typography>{antenalRecord.para}</Typography>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Live:
                </Typography>
                <Typography>{antenalRecord.live}</Typography>
              </Grid>
              <Grid item sm={3} xs={3}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Abortions:
                </Typography>
                <Typography>{antenalRecord.abortions}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Medical History:
                </Typography>
                <Typography>{antenalRecord.medicalHistory}</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Family History:
                </Typography>
                <Typography>{antenalRecord.familyHistory}</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography
                  variant="h5"
                  color={theme.palette.grey[700]}
                  sx={{ marginBottom: 1 }}
                >
                  Surgical History:
                </Typography>
                <Typography>{antenalRecord.surgicalHistory}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Grid sx={{ display: { sm: "block", xs: "none" } }}>
              {" "}
              <Typography
                variant="h5"
                color={theme.palette.grey[700]}
                sx={{ marginBottom: 2 }}
              >
                Obstetric History
              </Typography>
            </Grid>
            <Grid sx={{ display: { sm: "none", xs: "block" } }}>
              {" "}
              <Typography
                align="center"
                variant="h5"
                color={theme.palette.grey[700]}
                sx={{ marginBottom: 2 }}
              >
                Obstetric History
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: isSmallScreen ? "none" : { sm: "block", xs: "none" },
              }}
            >
              {" "}
              <TableContainer>
                <Table
                  size="small"
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "divider",
                  }}
                >
                  <TableHead sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableRow>
                      <TableCell sx={{ width: 80 }}>Sr. No.</TableCell>
                      <TableCell sx={{ width: 100 }}>Child Age</TableCell>
                      <TableCell sx={{ width: 100 }}>GA Weeks</TableCell>
                      <TableCell>Additional Details</TableCell>
                      <TableCell align="left" sx={{ width: 140 }}>
                        Mode of Delivery
                      </TableCell>
                      <TableCell align="left" sx={{ width: 70 }}>
                        Baby
                      </TableCell>
                      <TableCell align="left">Weight</TableCell>
                      <TableCell align="left">Remarks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {records?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {row.childAgeYear} yr {row.childAgeMonth} m
                        </TableCell>
                        <TableCell align="left">{row.gaWeeks}</TableCell>
                        <TableCell align="left">
                          {row.additionalDetails}
                        </TableCell>
                        <TableCell align="left">
                          {row.modeOfDeliveryDisplay}
                        </TableCell>
                        <TableCell align="left">{row.babyDisplay}</TableCell>
                        <TableCell align="left">{row.weight} kg</TableCell>
                        <TableCell align="left">{row.remarks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                {records?.map((row, index) => {
                  console.log("row", row);
                  return (
                    <Card
                      sx={{ padding: 2, marginTop: 2 }}
                      variant="outlined"
                      key={index}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">Sr. No.:</Typography>
                            <Stack>
                              <Typography> {index + 1} </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">Child Age:</Typography>
                            <Typography>
                              {" "}
                              {row.childAgeYear} yr {row.childAgeMonth} m
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">GA Weeks:</Typography>
                            <Typography>{row.gaWeeks}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Typography variant="h5">
                              Additional Details:
                            </Typography>
                            <Typography>{row.additionalDetails}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          {" "}
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">
                              Mode of Delivery:
                            </Typography>
                            <Typography>{row.modeOfDeliveryDisplay}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">Baby:</Typography>
                            <Typography>{row.babyDisplay}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                          >
                            <Typography variant="h5">Weight:</Typography>
                            <Typography>{row.weight} kg</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          {" "}
                          <Stack
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Typography variant="h5">Remarks:</Typography>
                            <Typography>{row.remarks}</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </Box>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Grid sx={{ display: { sm: "block", xs: "none" } }}>
              <Typography
                variant="h5"
                color={theme.palette.grey[700]}
                sx={{ marginBottom: 2 }}
              >
                Abortion History
              </Typography>
            </Grid>
            <Grid sx={{ display: { sm: "none", xs: "block" } }}>
              <Typography
                align="center"
                variant="h5"
                color={theme.palette.grey[700]}
                sx={{ marginBottom: 2 }}
              >
                Abortion History
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: isSmallScreen ? "none" : { sm: "block", xs: "none" },
              }}
            >
              {" "}
              <TableContainer>
                <Table
                  size="small"
                  sx={{
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "divider",
                  }}
                >
                  <TableHead sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableRow>
                      <TableCell sx={{ width: 80 }}>Sr. No.</TableCell>
                      <TableCell>Year of Abortion</TableCell>
                      <TableCell>Week of Abortion</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Note</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {abortionrecord?.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{row.yearOfAbortion}</TableCell>
                        <TableCell align="left">{row.weekOfAbortion}</TableCell>
                        <TableCell align="left">{row.typeDisplay}</TableCell>
                        <TableCell align="left">{row.note}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                {abortionrecord?.map((row, index) => (
                  <Card
                    sx={{ padding: 2, marginTop: 2 }}
                    variant="outlined"
                    key={index}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          spacing={2}
                        >
                          <Typography variant="h5">Sr. No.:</Typography>
                          <Stack>
                            <Typography> {index + 1} </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          spacing={2}
                        >
                          <Typography variant="h5">
                            Year of Abortion:
                          </Typography>
                          <Typography> {row.yearOfAbortion}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          spacing={2}
                        >
                          <Typography variant="h5">Type:</Typography>
                          <Typography>{row.typeDisplay}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={1}
                        >
                          <Typography variant="h5">Note:</Typography>
                          <Typography>{row.note}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Box>
            </Grid>
          </Card>
        </>
      ) : (
        <Typography sx={{ textAlign: "center", variant: "h4", margin: 4 }}>
          There are no Antenatal Records for this patient.
        </Typography>
      )}
    </>
  );
};

export default InvestigationAntenatalRecord;
