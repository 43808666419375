import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useLaporoscopyServices = () => {
  const axiosAuth = useAxiosAuth();

  const addLaproscopyReport = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Laproscopies`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getLaproscopyReportByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/Laproscopies/by-patient-id?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getLaproscopyReportByReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(`/api/Laproscopies/${reportId}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateLaproscopyReport = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/Laproscopies?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteLaproscopyRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/Laproscopies/delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addLaproscopyReport,
    getLaproscopyReportByPatientId,
    getLaproscopyReportByReportId,
    updateLaproscopyReport,
    deleteLaproscopyRecord,
  };
};

export default useLaporoscopyServices;
