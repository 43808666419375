import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import config from "config";
import { useTheme } from "@mui/material";
import usePatientReportService from "services/usePatientReportService";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { convertDateToLocalReverse } from "utils/DateOperations";

const ReportForm = (props) => {
  const theme = useTheme();
  const { updateReport, addReport, uploadReport } = usePatientReportService();
  const { patientId, onClose, onCancel, onSave, selectedReportData } = props;
  const [loading, setLoading] = useState(false);
  const [reportFile, setReportFile] = useState();
  const [reportFileError, setReportFileError] = useState("");
  console.log("PATIENT ID", patientId);

  const formik = useFormik({
    initialValues: {
      id: selectedReportData.id,
      patientId: patientId,
      title: selectedReportData.title,
      description: selectedReportData.description,
      date: convertDateToLocalReverse(selectedReportData.date),
      filePath: selectedReportData.filePath,
      fileType: selectedReportData.fileType,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      description: Yup.string(),
      date: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log("INSIDE UPDATE", selectedReportData);
      if (reportFile === undefined) {
        setReportFileError(
          "Please select the file to upload before submitting."
        );
      }
      setLoading(true);
      if (selectedReportData.id !== 0) {
        updateReport(selectedReportData.id, values)
          .then((response) => {
            if (response.status === 200) {
              onSave();
              setLoading(false);
              onClose();
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            setLoading(false);
            onClose();
          });
      } else {
        addReport(values)
          .then((response) => {
            if (response.status === 200) {
              uploadFile(response.data.newId);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            setLoading(false);
            onClose();
          });
      }
    },
  });

  console.log("INSIDE UPDATE", selectedReportData);

  const uploadFile = (id) => {
    if (reportFile === undefined) {
      return;
    }
    const formData = new FormData();
    // let fileExtension = '.pdf';
    // console.log(reportFile);

    // if (reportFile.type === 'application/pdf') {
    //     fileExtension = '.pdf';
    // } else if (reportFile.type === 'image/png') {
    //     fileExtension = '.docx';
    // } else {
    //     setReportFile(undefined);
    //     alert('Only PDF and WORD files are allowed');
    //     return;
    // }

    formData.append("file", reportFile, reportFile.name);

    uploadReport(id, formData)
      .then((response) => {
        if (response.status === 200) {
          setReportFile(undefined);
          onSave();
          onClose();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setReportFile(undefined);
      });
  };

  return (
    <div>
      <Grid align="center">
        {selectedReportData.patientId === 0 ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginX: "auto", alignItems: "center" }}
          >
            <FileCopyIcon fontSize="medium" color="primary" />
            <Typography variant="h4">Add New Report</Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginX: "auto", alignItems: "center" }}
          >
            <FileCopyIcon fontSize="medium" color="primary" />
            <Typography variant="h4">Edit Report Details</Typography>
          </Stack>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="title"
              label="Title"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.title}
              fullWidth
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              sx={{ marginBottom: 3, marginTop: 1 }}
            />

            <TextField
              name="description"
              label="Report Details"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              fullWidth
              multiline
              minRows={2}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ marginBottom: 3 }}
            />

            <TextField
              name="date"
              label="Date"
              type="date"
              variant="standard"
              onChange={formik.handleChange}
              value={formik.values.date}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              sx={{ marginBottom: 3 }}
            />
            {selectedReportData.id === 0 && (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ marginBottom: 1 }}
                >
                  <label htmlFor="btn-upload">
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: "none" }}
                      type="file"
                      multiple={false}
                      accept="application/pdf, image/png, image/jpg, image/jpeg"
                      disabled={loading}
                      onChange={(e) => {
                        setReportFile(e.target.files[0]);
                      }}
                    />

                    <Button
                      variant="outlined"
                      component="span"
                      color="primary"
                      startIcon={<UploadFileIcon />}
                      disabled={loading}
                      onClick={() => {
                        setReportFileError("");
                      }}
                    >
                      Browse File
                    </Button>
                  </label>
                  {reportFile && (
                    <Typography>
                      <b>Name:</b> {reportFile.name} (<b>Size:</b>{" "}
                      {(reportFile.size / 1024 / 1024).toFixed(2)} mb)
                    </Typography>
                  )}
                </Stack>
                <Typography color="error" sx={{ marginBottom: 3 }}>
                  {reportFileError}
                </Typography>
              </>
            )}

            <Divider sx={{ marginBottom: 2 }} />
            <Grid>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                disabled={loading}
                onClick={onCancel}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportForm;
