import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useScanSlotService = () => {
  const axiosAuth = useAxiosAuth();

  const getScanSlotSelection = async (date) => {
    try {
      const response = await axiosAuth.get(
        `/api/ScanSlots/selection?date=${date}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return { getScanSlotSelection };
};

export default useScanSlotService;
