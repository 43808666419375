import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useUserService = () => {
  const axiosAuth = useAxiosAuth();

  const getUserById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/Users?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getDoctors = async () => {
    try {
      const response = await axiosAuth.get(`/api/Users/doctors-list`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getStaffList = async () => {
    try {
      const response = await axiosAuth.get(`/api/Users/staff-list`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getUsersPaginated = async (skip, take) => {
    try {
      const response = await axiosAuth.get(
        `/api/Users/Peginated-List?skip=${skip}&take=${take}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const updateUser = async (id, values) => {
    try {
      const response = await axiosAuth.put(`/api/Users?id=${id}`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const addUser = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Users`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const softDeleteUser = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/Users/soft-Delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/Users/Delete?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getUserById,
    getDoctors,
    getStaffList,
    getUsersPaginated,
    updateUser,
    addUser,
    softDeleteUser,
    deleteUser,
  };
};

export default useUserService;
