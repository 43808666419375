import React, { createContext, memo, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  useTheme,
  CardHeader,
  Stack,
  ButtonGroup,
  Tooltip,
  Button,
  Divider,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportService from "services/useViabilityReport";
import useUserService from "services/useUserService";
import usePatientsService from "services/usePatientsService";
import ReportContent from "./ReportContent";
import PrintReportContext from "./ReportContext";
import useLaporoscopyServices from "services/useLaporoscopyServices";
import useHysterolaproscopyServices from "services/useHysterolaproscopyServices";
import useLummellaServices from "services/useLummellaServices";
import ReportContentDoctors from "./ReportContent";

const ReportDoctors = ({ reportType, selectedReport, handleDialogClose }) => {
  const theme = useTheme();
  //mediaquery
  const isSmallScreen = useMediaQuery("(max-width:770px)");

  const [searchParams] = useSearchParams();
  //set ReportId
  const [reportId, setReportId] = useState(undefined);
  //set report name
  const [reportName, setReportName] = useState(undefined);

  //save report data in this state
  const [reportdata, setReportData] = useState("");

  //save patien name data
  const [patientname, setPatientName] = useState();

  //save doctors name data
  const [doctorsdata, setDoctorsData] = useState();

  //call service for takeing patient name and report created doctor name
  const { getUserById } = useUserService();
  const { getPatientById } = usePatientsService();

  // useEffect(() => {
  //   if (reportId && reportName) {
  //     if (reportName.toLowerCase() == "laproscopy") {
  //       getLaproscopyReportByReportId(reportId)
  //         .then((result) => {
  //           setReportData(result.data);
  //         })

  //         .catch((err) => {
  //           console.log("err", err);
  //         });
  //     } else if (reportName.toLowerCase() == "hysterolaproscopy") {
  //       getHysterolaproscopyReportByReportId(reportId)
  //         .then((result) => {
  //           setReportData(result.data);
  //         })

  //         .catch((err) => {
  //           console.log("err", err);
  //         });
  //     } else if (reportName.toLowerCase() == "lummella") {
  //       getLummellaReportByReportId(reportId)
  //         .then((result) => {
  //           setReportData(result.data);
  //         })
  //         .catch((err) => {
  //           console.log("err", err);
  //         });
  //     }
  //   }
  // }, [reportId, reportName]);
  useEffect(() => {
    // alert(reportId);

    getUserById(selectedReport.userId)
      .then((result) => {
        setDoctorsData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getPatientById(selectedReport.patientId)
      .then((result) => {
        setPatientName(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedReport]);

  // useEffect(() => {
  //   // if (selectedReport) {
  //   console.log("inside", selectedReport?.id);
  //   setReportData(selectedReport);
  //   // }
  // }, [selectedReport]);
  return (
    <>
      <PrintReportContext.Provider
        value={{
          selectedReport,
          reportType,
          reportName,
          reportId,
          doctorsdata,
          patientname,
          theme,
          isSmallScreen,
          handleDialogClose,
        }}
      >
        <ReportContentDoctors />
      </PrintReportContext.Provider>
    </>
  );
};

export default memo(ReportDoctors);
