//Use this for showing date on UI
const convertDateToLocal = (dateInput) => {
  let dateValue = new Date(dateInput); //.toLocaleString('hi-IN');
  let day = dateValue.getDate().toString().padStart(2, "0");
  let month = (dateValue.getMonth() + 1).toString().padStart(2, "0");
  let year = dateValue.getFullYear();
  return `${day}-${month}-${year}`;
};

//Use this for showing date in textfield on UI (use this for forms add & edit)
const convertDateToLocalReverse = (dateInput) => {
  let dateValue = new Date(dateInput); //.toLocaleString('hi-IN');
  let day = dateValue.getDate().toString().padStart(2, "0");
  let month = (dateValue.getMonth() + 1).toString().padStart(2, "0");
  let year = dateValue.getFullYear();
  return `${year}-${month}-${day}`;
};

const getDateDifference = (date1, date2) => {
  let date_1 = new Date(date1);
  let date_2 = new Date(date2);
  let diffTime = date_1 - date_2;
  let diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
  return diff;
};

const addDays = (date, days) => {
  var date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
};

export {
  convertDateToLocal,
  convertDateToLocalReverse,
  getDateDifference,
  addDays,
};
