import React from "react";
import {
  Dialog,
  DialogActions,
  Typography,
  Divider,
  Button,
  DialogTitle,
  useTheme,
  DialogContent,
} from "@mui/material";
const DeleteDialog = ({ setOpenDeleteDlg, handleDelete, openDeleteDlg }) => {
  const theme = useTheme();
  return (
    <>
      <Dialog
        open={openDeleteDlg}
        onClose={() => {
          setOpenDeleteDlg(false);
        }}
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h4" color={theme.palette.secondary.dark}>
            Delete Item
          </Typography>
          <Divider sx={{ marginTop: 2 }}></Divider>
        </DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDlg(false);
              handleDelete();
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteDlg(false);
            }}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
