//project imports
import { setUser } from "../store/userSlice";
import { axiosAuth, axiosOpen } from "../services/apiBase";

//Third party imports
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import packageJson from "../../package.json";

const useAxiosAuth = () => {
  const { userId, userName, role, displayRole, accessToken } = useSelector(
    (state) => state.userReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
      })
    );
    navigate("/login");
  };

  // const caching = () => {
  //   let version = localStorage.getItem("version");
  //   if (version == null) {
  //     localStorage.setItem("version", packageJson.version);
  //   }
  //   if (version != packageJson.version) {
  //     if ("caches" in window) {
  //       caches.keys().then((names) => {
  //         // Delete all the cache files
  //         names.forEach((name) => {
  //           caches.delete(name);
  //         });
  //       });

  //       // Makes sure the page reloads. Changes are only visible after you refresh.
  //       window.location.reload(true);
  //     }

  //     localStorage.clear();
  //     localStorage.setItem("version", packageJson.version);
  //   }
  // };

  useEffect(() => {
    //caching();

    axiosAuth.interceptors.request.use(async (req) => {
      let accessToken = localStorage.getItem("accessToken");
      req.headers["Authorization"] = `Bearer ${accessToken}`;
      const decodedToken = jwt_decode(accessToken);
      const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;

      let request = {
        accessToken: accessToken,
      };

      try {
        const response = await axiosOpen.post(
          "/api/Authentication/refresh-token",
          request
        );
        localStorage.setItem("accessToken", response.data.accessToken);
        req.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
        return req;
      } catch (error) {
        alert("Your session exired, login again.");
        handleLogout();
        return;
      }
    });

    // return () => {
    //   axiosAuth.interceptors.request.eject(requestIntercept);
    // };
  }, [accessToken]); //add auth
  return axiosAuth;
};

export default useAxiosAuth;
