// assets
import { IconTestPipe } from "@tabler/icons";

// constant
const icons = {
  IconTestPipe,
};

// ==============================|| TECHNICIAN MENU ITEMS ||============================== //

const scanlabMenuItems = [
  {
    id: "lab-main-pages",
    type: "group",
    children: [
      {
        id: "investigations",
        title: "Investigations",
        type: "item",
        icon: icons.IconTestPipe,
        url: "/investigations",
        breadcrumbs: false,
      },
      {
        id: "slotwise-scans",
        title: "Slotwise Scans",
        type: "item",
        icon: icons.IconTestPipe,
        url: "/slotwise-scans",
        breadcrumbs: false,
      },
    ],
  },
];

export default scanlabMenuItems;
