// assets
import {
  IconDashboard,
  IconUsers,
  IconCalendarTime,
  IconBed,
  IconFileInvoice,
  IconReportAnalytics,
  IconSettings,
  IconTestPipe,
  IconCertificate,
  IconBandage,
  IconReceipt,
  IconCurrencyRupee,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconUsers,
  IconCalendarTime,
  IconBed,
  IconFileInvoice,
  IconReportAnalytics,
  IconSettings,
  IconTestPipe,
  IconCertificate,
  IconBandage,
  IconReceipt,
  IconCurrencyRupee,
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const frontDeskMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "patients",
        title: "Patients",
        type: "item",
        url: "/patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
      {
        id: "filtered-patients",
        title: "Filtered Patients",
        type: "item",
        url: "/filtered-patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "fd-main-pages",
    type: "group",
    children: [
      {
        id: "appointments",
        title: "Appointments",
        type: "item",
        icon: icons.IconCalendarTime,
        url: "/appointments",
        breadcrumbs: false,
      },
      {
        id: "daycare-overview",
        title: "Day Care Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/daycare-overview",
        breadcrumbs: false,
      },
      {
        id: "ipd-overview",
        title: "IPD Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/ipd-overview",
        breadcrumbs: false,
      },
      {
        id: "additional-treatment-billing",
        title: "Additional Treatment Bills",
        type: "item",
        icon: icons.IconBandage,
        url: "/additional-treatment-billing",
        breadcrumbs: false,
      },
      {
        id: "investigation-billing",
        title: "Investigation Bills",
        type: "item",
        icon: icons.IconFileInvoice,
        url: "/investigation-billing",
        breadcrumbs: false,
      },
      {
        id: "scan-billing",
        title: "Scan Bills",
        type: "item",
        icon: icons.IconFileInvoice,
        url: "/scan-billing",
        breadcrumbs: false,
      },
      {
        id: "opdReceipts",
        title: "OPD Receipts",
        type: "item",
        icon: icons.IconReceipt,
        url: "/opd-receipts",
        breadcrumbs: false,
      },
      {
        id: "billing",
        title: "IPD Bills",
        type: "collapse",
        icon: icons.IconFileInvoice,
        breadcrumbs: false,
        children: [
          {
            id: "advance-payments",
            title: "Advance Payments",
            type: "item",
            url: "/advance-payments",
            breadcrumbs: false,
          },
          {
            id: "ipd-billing",
            title: "IPD Bills",
            type: "item",
            url: "/billing",
            breadcrumbs: false,
          },
          {
            id: "discharge-cards",
            title: "Discharge Cards",
            type: "item",
            url: "/discharge-cards",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "operating-expenses",
        title: "Expenses",
        type: "item",
        icon: icons.IconCurrencyRupee,
        url: "/operating-expenses",
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "main-settings",
    type: "group",
    children: [
      {
        id: "setting-pages",
        title: "Settings",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "bill-items",
            title: "Bill Items",
            type: "item",
            url: "/bill-items",

            breadcrumbs: false,
          },
          {
            id: "opd-receipt-item",
            title: "OPD Receipt Items",
            type: "item",
            url: "/opd-receipt-item-master",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
  {
    id: "report-pages",
    type: "group",
    children: [
      {
        id: "reports-pages",
        title: "Reports",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "summary-report",
            title: "Summary Report",
            type: "item",
            url: "/summary-report",
            breadcrumbs: false,
          },
          {
            id: "medication-report",
            title: "Medication Report",
            type: "item",
            url: "/medication-report",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default frontDeskMenuItems;
