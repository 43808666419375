import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Tabs, Tab, Box } from "@mui/material";
import useInvestigationRecordService from "services/useInvestigationRecordService";
import NoReport from "ui-component/formcomponent/NoReport";
import ReportCard from "./ReportCard";
import { useDialogOpenration } from "context/DialogContex";
import DialogComponent from "ui-component/dialog/DialogComponent";
import InvestigationRecordForm from "./InvestigationRecordForm";
import { useSelector } from "react-redux";
import Comparision from "./Comparision";

const InvestigationsRecords = ({ patient }) => {
  console.log("patient", patient);
  const { role } = useSelector((state) => state.userReducer);

  //state
  const [investigationRecord, setInvestigationRecord] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [tabValue, setTabValue] = useState(0);

  //Methods
  const handleTabChange = (e, newVal) => {
    setTabValue(newVal);
  };

  //services
  const {
    getInvestigationsRecordList,
    getInvestigationsRecordListByPatientId,
  } = useInvestigationRecordService();
  const { setOpenDialog, openDialog } = useDialogOpenration();
  const [formDialog, setFormDialog] = useState(false);
  useEffect(() => {
    loadInvestigationRecord();
  }, []);
  const loadInvestigationRecord = async () => {
    try {
      const response = await getInvestigationsRecordListByPatientId(
        patient?.id
      );

      setInvestigationRecord(response.data.investigationRecords);
    } catch (err) {
      console.log(err);
    }
  };
  const loadSelectedReport = (data) => {
    setSelectedReport(data);
  };

  return (
    <>
      <Grid container direction="column" mt={5}>
        <Grid item sm={6} xs={6}>
          <Typography align="left" variant="h4" color="secondary">
            Investigation Records
          </Typography>
        </Grid>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Investigation Records"></Tab>
            <Tab label="Compare All"></Tab>
          </Tabs>
        </Box>
        {tabValue == 0 && (
          <>
            <Grid
              item
              align="right"
              xs={6}
              sx={{ display: role == 7 ? "none" : "block" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedReport({ id: 0, date: new Date() });
                  setOpenDialog(true);
                  setFormDialog(true);
                }}
              >
                Add New Investigation
              </Button>
            </Grid>

            <DialogComponent>
              <InvestigationRecordForm
                loadInvestigationRecord={loadInvestigationRecord}
                selectedReport={selectedReport}
                loadSelectedReport={loadSelectedReport}
              />
            </DialogComponent>

            {Array.isArray(investigationRecord) &&
            investigationRecord.length > 0 ? (
              <ReportCard
                reportData={investigationRecord}
                loadInvestigationRecord={loadInvestigationRecord}
                loadSelectedReport={loadSelectedReport}
              />
            ) : (
              <NoReport />
            )}
          </>
        )}
        {tabValue == 1 && <Comparision reportData={investigationRecord} />}
      </Grid>
    </>
  );
};

export default InvestigationsRecords;
