import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useViabilityReportPhotoService = () => {
  const axiosAuth = useAxiosAuth();

  const addViabilityReportPhoto = async (id, fd) => {
    try {
      const response = await axiosAuth.post(
        `/api/ViabilityScanPhotos/upload-viabilityscan-photo?sonographyId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getViabilityPhotoByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/ViabilityScanPhotos?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getViabilityPhotoByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/ViabilityScanPhotos/by-viabilityscan-id?sonographyId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteViabilityPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/ViabilityScanPhotos?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addViabilityReportPhoto,
    getViabilityPhotoByPatientId,
    getViabilityPhotoByScanReportId,
    deleteViabilityPhoto,
  };
};
export default useViabilityReportPhotoService;
