import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useInvestigationItemService = () => {
  const axiosAuth = useAxiosAuth();

  const getInvestigationItemSlotwiseByDate = async (date) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/slot-wise-scans?date=${date}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemsById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationItems/${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemCountListByStatus = async (status) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/count-by-patient?status=${status}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemsListByLabType = async (id, labType, type = -1) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/filterd-list?appointmentId=${id}&labType=${labType}&type=${type}&status=${-1}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getInvestigationItemsListByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/filterd-list?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemsListByStatus = async (status) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/filterd-list?status=${status}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemsListByPatientAndStatus = async (
    id,
    status,
    labType,
    type,
    date
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItems/filterd-list?patientId=${id}&status=${status}&labType=${labType}&type=${type}&date=${date}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvesitationCountByPatient = async (text, status, labType, date) => {
    try {
      if (status == undefined) {
        const response = await axiosAuth.get(
          `/api/InvestigationItems/count-by-patient`
        );
        return handleResponse(response);
      } else {
        const response = await axiosAuth.get(
          `/api/InvestigationItems/count-by-patient?searchText=${text}&labType=${labType}&date=${date}`
        );
        return handleResponse(response);
      }
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationItemsList = async () => {
    try {
      const response = await axiosAuth.get(`/api/InvestigationItems`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addInvestigationItems = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/InvestigationItems`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addSingleInvestigationItems = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/InvestigationItems/create-single`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateInvestigationItems = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItems?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteInvestigationItems = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/InvestigationItems?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const statusUpdateInvestigationItems = async (id, status, userId, amount) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItems/update-status?id=${id}&status=${status}&userId=${userId}&amount=${amount}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const statusUpdateWithAmountInvestigationItems = async (
    id,
    status,
    userId,
    amount
  ) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItems/update-status?id=${id}&status=${status}&userId=${userId}&amount=${amount}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const typeUpdateWithInvestigationItems = async (id, type, labType) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItems/update-type?id=${id}&type=${type}&labType=${labType}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateScanSlot = async (id, scanSlotId) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItems/update-scan-slot?id=${id}&scanslotId=${scanSlotId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getInvestigationItemSlotwiseByDate,
    getInvestigationItemsById,
    getInvestigationItemCountListByStatus,
    getInvestigationItemsListByLabType,
    getInvestigationItemsListByPatientId,
    getInvestigationItemsListByPatientAndStatus,
    getInvesitationCountByPatient,
    getInvestigationItemsList,
    addInvestigationItems,
    addSingleInvestigationItems,
    updateInvestigationItems,
    deleteInvestigationItems,
    statusUpdateInvestigationItems,
    statusUpdateWithAmountInvestigationItems,
    typeUpdateWithInvestigationItems,
    updateScanSlot,
  };
};

export default useInvestigationItemService;
