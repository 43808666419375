import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useSonographyService = () => {
  const axiosAuth = useAxiosAuth();

  const addSonographyReport = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Sonographies`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getSonographyByPatientId = async (id, reportType = null) => {
    try {
      const response = await axiosAuth.get(
        `/api/Sonographies/by-filter?patientId=${id}&type=${reportType}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getBySonographyReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(`/api/Sonographies/${reportId}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateSonographyRecord = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/Sonographies?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteSonographyRecord = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/Sonographies/delete?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addSonographyReport,
    getSonographyByPatientId,
    getBySonographyReportId,
    updateSonographyRecord,
    deleteSonographyRecord,
  };
};

export default useSonographyService;
