import {
  Divider,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Button,
  Table,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useRef, useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import PrintReport from "./PrintReport";

const InvestigationReportView = ({ selectedReport, handleDialogClose }) => {
  const componentRef = useRef();

  const [printCmd, setPrintCmd] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (printCmd) {
      handlePrint();
    }
  }, [printCmd]);
  const onPrintComplete = () => {
    setPrintCmd(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  return (
    <>
      <Grid conatiner>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="primary">
            {selectedReport.title}
          </Typography>
        </Grid>
        <Divider sx={{ marginTop: 2 }} />
        <Grid item xs={12} mt={2}>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                <TableRow>
                  <TableCell>Investigation</TableCell>
                  <TableCell>Normal Value</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedReport.investigationRecordItems.map((row) => {
                  return (
                    <TableRow>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        {`${row.min} ${row.unit} - ${row.max} ${row.unit}`}
                        {/* {row.unit}-{row.max}
                      {row.unit} */}
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {row.value} {row.unit}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row.remark != "" ? row.remark : "--"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Stack direction="row">
            <Typography variant="h4">Comment:</Typography>
            <Typography paddingX={2}>{selectedReport?.remark}</Typography>
          </Stack>
        </Grid>
        <Stack columnGap={2} direction="row" mt={2}>
          <LoadingButton
            loadingPosition="center"
            endIcon={<PrintIcon />}
            variant="contained"
            onClick={() => {
              setPrintCmd(true);
            }}
          >
            Print
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              handleDialogClose();
            }}
          >
            Close
          </Button>
        </Stack>
        <div style={{ display: "none" }}>
          <PrintReport selectedReport={selectedReport} ref={componentRef} />
        </div>
      </Grid>
    </>
  );
};

export default InvestigationReportView;
