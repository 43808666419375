import { Card, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import useMenstrualHistory from "services/useMenstrualHistoryService";
import { convertDateToLocalReverse } from "utils/DateOperations";

const InvestigationMenstrualHistory = ({ patientData }) => {
  const theme = useTheme();
  const { getMenstrualHistoryById } = useMenstrualHistory();

  useEffect(() => {
    loadMenstrualHistory();
  }, []);

  const loadMenstrualHistory = async () => {
    if (patientData) {
      const response = await getMenstrualHistoryById(patientData?.id);
      console.log("MENSTRUAL HISTORY", response);
      setMenstrualHistoryList(response.data.patientMenstrualHistories);
    }
  };

  const [menstrualHistoryList, setMenstrualHistoryList] = useState();
  return (
    <>
      <Grid container spacing={2}>
        {Array.isArray(menstrualHistoryList) &&
          menstrualHistoryList?.map((ele) => {
            return (
              <>
                {" "}
                <Grid item xs={12} sm={12}>
                  {" "}
                  <Typography variant="h4">Menstrual History</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{ padding: 2, backgroundColor: "#FFFAF0" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LMP Date 1:
                        </Typography>
                        <Typography>
                          {ele.lmpDate1 != "0001-01-01T00:00:00"
                            ? convertDateToLocalReverse(ele.lmpDate1)
                            : "---"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LMP Date 2:
                        </Typography>
                        <Typography>
                          {ele.lmpDate2 != "0001-01-01T00:00:00"
                            ? convertDateToLocalReverse(ele.lmpDate2)
                            : "---"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LMP Date 3:
                        </Typography>
                        <Typography>
                          {ele.lmpDate3 != "0001-01-01T00:00:00"
                            ? convertDateToLocalReverse(ele.lmpDate3)
                            : "---"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LMP Date 4:
                        </Typography>
                        <Typography>
                          {ele.lmpDate4 != "0001-01-01T00:00:00"
                            ? convertDateToLocalReverse(ele.lmpDate4)
                            : "---"}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          Age Of Monarchs:
                        </Typography>
                        <Typography>{ele.ageOfMonarchs}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          Menopause Year:
                        </Typography>
                        <Typography>{ele.menopauseYear}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          Menopause Month:
                        </Typography>
                        <Typography>{ele.menopauseMonth}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          Menopause Type:
                        </Typography>
                        <Typography>{ele.menopauseTypeDisplay}</Typography>
                      </Grid>

                      {ele.menopauseType == -1 && (
                        <>
                          <Grid item xs={3}>
                            <Typography
                              variant="h5"
                              color={theme.palette.grey[700]}
                              sx={{ marginBottom: 1 }}
                            >
                              Cycle:
                            </Typography>
                            <Typography>{ele.cycleDisplay}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="h5"
                              color={theme.palette.grey[700]}
                              sx={{ marginBottom: 1 }}
                            >
                              Flow:
                            </Typography>
                            <Typography>{ele.flowDisplay}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="h5"
                              color={theme.palette.grey[700]}
                              sx={{ marginBottom: 1 }}
                            >
                              Pain:
                            </Typography>
                            <Typography>{ele.painDisplay}</Typography>
                          </Grid>
                          <Grid item xs={3}></Grid>
                        </>
                      )}

                      <Grid item xs={6}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LAM:
                        </Typography>
                        <Typography>{ele.lam}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          LAM:
                        </Typography>
                        <Typography>{ele.lamRemark}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="h5"
                          color={theme.palette.grey[700]}
                          sx={{ marginBottom: 1 }}
                        >
                          Comments:
                        </Typography>
                        <Typography>{ele.comment}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            );
          })}
        {menstrualHistoryList?.length == 0 ? (
          <Grid item xs={12} sm={12}>
            {" "}
            <Typography sx={{ textAlign: "center", variant: "h4", margin: 4 }}>
              There is no Menstrual History for this patient.
            </Typography>
          </Grid>
        ) : null}
      </Grid>{" "}
    </>
  );
};

export default InvestigationMenstrualHistory;
