import React, { useContext, useEffect, useState } from "react";
import LetterHead from "ui-component/LetterHead";
import {
  Typography,
  Stack,
  useTheme,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from "@mui/material";
import { convertDateToLocal } from "utils/DateOperations";
import usePatientsService from "services/usePatientsService";
import theme from "themes";
const PrintReport = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const { selectedReport } = props;
  const [patientData, setPatientData] = useState();
  const getPageMargins = () => {
    return `@page { margin: ${"40px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const pageStyle = "@page {size: A4 portrait;}";
  //actual data print here
  const { getPatientById } = usePatientsService();

  //get patient details
  useEffect(() => {
    if (selectedReport) {
      getPatientById(selectedReport.patientId)
        .then((response) => {
          setPatientData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <div style={{ position: "fixed", top: "0", width: "100%" }}>
          <LetterHead />
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <div style={{ height: "100px" }}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <Typography variant="h5" align="right" sx={{ marginY: 1 }}>
                    Date: {convertDateToLocal(selectedReport.date)}
                  </Typography>
                  <Typography variant="h4" sx={{ marginY: 1 }}>
                    Patient Details
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ marginTop: 0.5 }}
                  >
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      {patientData?.patientMaster?.salutationDisplay +
                        " " +
                        patientData?.patientMaster?.firstName +
                        " " +
                        patientData?.patientMaster?.middleName +
                        " " +
                        patientData?.patientMaster?.lastName}
                    </Typography>
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      {patientData?.uhid}
                    </Typography>
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      {patientData?.patientMaster?.genderDisplay}
                    </Typography>
                    <Typography
                      sx={{ color: theme.palette.grey[500] }}
                      fontWeight="medium"
                    >
                      |
                    </Typography>
                  </Stack>

                  <Typography variant="h4" align="center" mt={5} padding={2}>
                    {selectedReport?.title}
                  </Typography>
                  <TableContainer sx={{ mb: 2 }}>
                    <Table>
                      <TableHead
                        sx={{ backgroundColor: theme.palette.grey[100] }}
                      >
                        <TableRow>
                          <TableCell>Investigation</TableCell>
                          <TableCell width={150} align="left">
                            Referance Range
                          </TableCell>
                          <TableCell width={150} align="left">
                            Result
                          </TableCell>
                          <TableCell width={150} align="left">
                            Remark
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedReport.investigationRecordItems.map((row) => {
                          return (
                            <TableRow>
                              <TableCell>{row.title}</TableCell>
                              <TableCell align="left">{`${row.min} - ${row.max}`}</TableCell>
                              <TableCell align="left">
                                <Typography variant="h4">
                                  {row.value} {row.unit}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>
                                  {row.remark != "" ? row.remark : "--"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  <br />
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h4" align="left">
                      Coments:
                    </Typography>
                    <Typography variant="h4" align="left">
                      {selectedReport.remark}
                    </Typography>
                  </Stack>

                  <br />
                  <br />
                  <br />
                  <br />

                  <Typography variant="h5">Authorized Signature</Typography>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default PrintReport;
