import React, { useContext } from "react";
import LetterHead from "ui-component/LetterHead";
import ReportContent from "./ReportContent";
import PrintReportContext from "./ReportContext";
import { Typography, Stack, Grid, Divider } from "@mui/material";
import ReportCardData from "./ReportCardData";
import calculateAge from "utils/calculateAge";
import { convertDateToLocal } from "utils/DateOperations";
const PrintReport = React.forwardRef((props, ref) => {
  const { reportdata, reportName, reportId, doctorsdata, patientname, theme } =
    useContext(PrintReportContext);
  const getPageMargins = () => {
    return `@page { margin: ${"40px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const pageStyle = "@page {size: A4 portrait;}";
  //actual data print here
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <div style={{ position: "fixed", top: "0", width: "100%" }}>
          <LetterHead />
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <div style={{ height: "100px" }}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <Typography variant="h4" align="center" sx={{ mb: 2 }}>
                    {reportName == 1
                      ? "Viablity Scan "
                      : reportName == 2
                      ? "NT Scan (Nuchal Translucency) "
                      : reportName == 3
                      ? "Growth scan "
                      : reportName == 4
                      ? "Anomaly scan "
                      : reportName == 5
                      ? "Colour Doppler "
                      : ""}
                    Report
                  </Typography>
                  <Grid align="right" sx={{ mb: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      {" "}
                      <Typography variant="subtitle1">Date: </Typography>
                      <Typography>
                        {convertDateToLocal(reportdata?.date)}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid sx={{ marginY: 2 }}>
                    <Stack spacing={1}>
                      <Typography variant="h4">
                        {patientname?.patientMaster.salutationDisplay +
                          " " +
                          patientname?.patientMaster.firstName +
                          " " +
                          patientname?.patientMaster.middleName +
                          " " +
                          patientname?.patientMaster.lastName}
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {" "}
                        <Typography
                          sx={{ color: theme.palette.grey[500] }}
                          fontWeight="medium"
                        >
                          {patientname?.uhid}
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.grey[500] }}
                          fontWeight="medium"
                        >
                          |
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.grey[500] }}
                          fontWeight="medium"
                        >
                          {patientname?.patientMaster.genderDisplay}
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.grey[500] }}
                          fontWeight="medium"
                        >
                          |
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.grey[500] }}
                          fontWeight="medium"
                        >
                          {calculateAge(
                            patientname?.patientMaster.birthDate
                          ).toString() + " yrs"}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Divider sx={{ marginY: 1 }} />

                  <ReportCardData id={0} />
                  <br />
                  <br />
                  <Typography fontSize={16} textAlign="right">
                    Thank you!
                  </Typography>
                  <br />
                  <br />
                  <br />
                  <Typography variant="h4" textAlign="right">
                    Authorized Signature
                  </Typography>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default PrintReport;
