import {
  Card,
  Divider,
  Typography,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import React, { useEffect, useState } from "react";
import useInvestigationItemsMasterService from "services/useInvestigationItemsMasterService";
import useInvestigationItemService from "services/useInvestigationItemService";
import { LoadingButton } from "@mui/lab";
import { convertDateToLocalReverse } from "utils/DateOperations";
import useScanSlotService from "services/useScanSlotService";

const ScanItemsForm = ({
  onClose,
  appointmentId,
  patientId,
  investigationItems,
  onSave,
  fromAppointment,
}) => {
  //Services
  const { getInvestigationMasterItemList } =
    useInvestigationItemsMasterService();
  const { addInvestigationItems } = useInvestigationItemService();

  const { getScanSlotSelection } = useScanSlotService();

  //States
  const [scanItemMaster, setScanItemMaster] = useState();
  const [checked, setChecked] = React.useState([0]);
  const [selectedScanItems, setSelectedScanItems] = useState([]);
  const [finalScanList, setFinalScanList] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [dateValues, setDateValues] = useState(
    convertDateToLocalReverse(new Date())
  );

  const [minDate, setMinDate] = useState(convertDateToLocalReverse(new Date()));

  const [slotListPerItem, setSlotListPerItem] = useState({});

  const [selectedSlot, setSelectedSlot] = useState({});

  //Methods
  const handleSlotSelection = (itemId, slotId) => {
    setSelectedSlot((prevSelectedSlot) => ({
      ...prevSelectedSlot,
      [itemId]: slotId,
    }));

    // Find the selected scan item and update its slot details
    const updatedSelectedScanItems = selectedScanItems.map((item) => {
      if (item.id === itemId) {
        const selectedSlot = slotListPerItem[itemId].find(
          (slot) => slot.id === slotId
        );
        return {
          ...item,
          scanSlotId: selectedSlot.id,
          scanNumber: selectedSlot.number,
          slotType: selectedSlot.slotType,
          slotTime: selectedSlot.time,
        };
      }
      return item;
    });

    setSelectedScanItems(updatedSelectedScanItems);
  };

  const handleDateChange = (id, date) => {
    setDateValues((prevDateValues) => ({
      ...prevDateValues,
      [id]: date,
    }));

    // Reset the selected slot for the corresponding item
    setSelectedSlot((prevSelectedSlot) => ({
      ...prevSelectedSlot,
      [id]: null,
    }));

    // Update the selected scan items to remove the slot details
    const updatedSelectedScanItems = selectedScanItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          scanSlotId: null,
          scanNumber: null,
          slotType: null,
          slotTime: "",
        };
      }
      return item;
    });
    setSelectedScanItems(updatedSelectedScanItems);

    gatherScanSlotList(date, id);
  };

  const gatherScanSlotList = (date, itemId) => {
    getScanSlotSelection(date)
      .then((response) => {
        setSlotListPerItem((prevSlotListPerItem) => ({
          ...prevSlotListPerItem,
          [itemId]: response.data.scanSlots,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const gatherScanItemList = () => {
    getInvestigationMasterItemList(1)
      .then((response) => {
        //Function to compare both arrays and show only those objects of arr2 which are not present in arr1
        const arr1 = investigationItems.map((item) => item.name);
        const arr2 = response.data.investigationItemsMasters.filter(
          (item) => !arr1.includes(item.name)
        );
        setScanItemMaster(arr2);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addInvestigations = () => {
    const internalItemsWithoutSlot = selectedScanItems.filter(
      (item) => item.type === 0 && !selectedSlot[item.id]
    );

    if (internalItemsWithoutSlot?.length > 0) {
      // Show an error message or handle the error condition
      alert("Please select a slot for internal investigations.");
      return;
    }

    // Group items by date
    const itemsByDate = {};
    selectedScanItems.forEach((item) => {
      const createDate =
        dateValues[item.id] || convertDateToLocalReverse(new Date());
      if (!itemsByDate[createDate]) {
        itemsByDate[createDate] = [];
      }
      itemsByDate[createDate].push(item);
    });

    // Check if any items have the same slot ID on the same date
    const hasDuplicateSlots = Object.values(itemsByDate).some((items) => {
      const slotIds = new Set();
      return items.some((item) => {
        if (item.type === 0 && item.scanSlotId) {
          if (slotIds.has(item.scanSlotId)) {
            return true; // Duplicate slot ID found
          }
          slotIds.add(item.scanSlotId);
        }
        return false;
      });
    });

    if (hasDuplicateSlots) {
      alert("Same slot selected for scans on the same date.");
      return;
    }

    const investigationItemsWithDates = selectedScanItems.map((item) => {
      const createDate =
        dateValues[item.id] || convertDateToLocalReverse(new Date());
      return {
        ...item,
        createDate,
      };
    });

    let data = {
      investigationItems: investigationItemsWithDates,
    };

    setSubmitting(true);

    addInvestigationItems(data)
      .then((response) => {
        setSubmitting(false);
        onSave();
        onClose();
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  // const handleToggle = (value, index) => () => {
  //   const currentIndex = checked.indexOf(value);

  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //     setSelectedScanItems([
  //       ...selectedScanItems,
  //       {
  //         id: value.id,
  //         appointmentId: appointmentId,
  //         patientId: patientId,
  //         name: value.name,
  //         description: value.description,
  //         type: value.type,
  //         labType: value.labType,
  //         amount: value.amount,
  //       },
  //     ]);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //     selectedScanItems.splice(index, 1);
  //   }

  //   setChecked(newChecked);
  // };

  const handleToggle = (value, index) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectedScanItems([
        ...selectedScanItems,
        {
          id: value.id,
          appointmentId: appointmentId,
          patientId: patientId,
          name: value.name,
          description: value.description,
          type: value.type,
          labType: value.labType,
          amount: value.amount,
          // slotId: null,
          // slotNumber: null,
          // slotType: null,
          // slotTypeDisplay: null,
          slotTime: "", // Set slotTime to "" for external items
        },
      ]);

      // Call gatherScanSlotList with the current date for the newly selected item
      gatherScanSlotList(
        dateValues[value.id] || convertDateToLocalReverse(new Date()),
        value.id
      );
    } else {
      newChecked.splice(currentIndex, 1);
      selectedScanItems.splice(index, 1);
    }

    setChecked(newChecked);
  };

  //UseEffects
  useEffect(() => {
    gatherScanItemList();
  }, []);
  const changeType = (singleRecord, type) => {
    selectedScanItems.forEach((ele) => {
      if (ele.id == singleRecord.id) {
        ele.type = type;
      }
    });

    if (scanItemMaster) {
      scanItemMaster?.forEach((ele) => {
        if (ele.id == singleRecord.id) {
          ele.type = type;
        }
      });
      setChangeCount(changeCount + 1);
      setScanItemMaster(scanItemMaster);
    }
  };

  useEffect(() => {
    gatherScanSlotList(convertDateToLocalReverse(new Date()));
  }, []);
  return (
    <>
      <Grid sx={{ padding: fromAppointment ? 2 : null }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginX: "auto", alignItems: "center" }}
        >
          <AdfScannerIcon fontSize="medium" color="primary" />
          <Typography variant="h4">Suggested Scans</Typography>
        </Stack>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid
        container
        sx={{
          maxHeight: 600,
          overflow: "auto",
          padding: fromAppointment ? 2 : null,
        }}
      >
        <Grid item sm={12}>
          <Typography>Select the scans you want to suggest</Typography>
        </Grid>
        {scanItemMaster != undefined ? (
          <>
            <Grid item sm={12}>
              <List>
                {scanItemMaster?.map((row, index) => {
                  const labelId = `checkbox-list-label-${row}`;

                  return (
                    <Grid container>
                      <Grid item sm={12} md={12}>
                        <Card
                          variant="outlined"
                          sx={{
                            margin: 0.5,
                          }}
                        >
                          <ListItem
                            key={index}
                            secondaryAction={
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant={
                                    row.type == 0 ? "contained" : "outlined"
                                  }
                                  onClick={() => {
                                    changeType(row, 0);
                                  }}
                                  disabled={checked.indexOf(row) == -1}
                                >
                                  Internal
                                </Button>
                                <Button
                                  variant={
                                    row.type == 1 ? "contained" : "outlined"
                                  }
                                  onClick={() => {
                                    changeType(row, 1);
                                  }}
                                  disabled={checked.indexOf(row) == -1}
                                >
                                  External
                                </Button>
                              </Stack>
                            }
                            disablePadding
                          >
                            <ListItemButton
                              role={undefined}
                              //onClick={handleToggle(row, index)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(row) !== -1}
                                  onClick={handleToggle(row, index)}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  <>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Typography variant="h5">
                                        {row.name}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight="light"
                                      >
                                        |
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        fontWeight="light"
                                      >
                                        {row.typeDisplay}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight="light"
                                      >
                                        |
                                      </Typography>

                                      <Typography
                                        color="secondary"
                                        variant="h6"
                                      >
                                        ₹ {row.amount}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight="light"
                                      >
                                        |
                                      </Typography>
                                      <Typography variant="h6">
                                        Date:
                                      </Typography>
                                      {row.type === 0 &&
                                        checked.indexOf(row) !== -1 && (
                                          <TextField
                                            type="date"
                                            variant="standard"
                                            value={
                                              dateValues[row.id] ||
                                              convertDateToLocalReverse(
                                                new Date()
                                              )
                                            }
                                            onChange={(e) =>
                                              handleDateChange(
                                                row.id,
                                                e.target.value
                                              )
                                            }
                                            InputProps={{
                                              inputProps: {
                                                min: minDate,
                                              },
                                            }}
                                          />
                                        )}
                                    </Stack>
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                          <Grid
                            container
                            sx={{ paddingLeft: 1, marginBottom: 1 }}
                          >
                            {row.type === 0 &&
                              checked.indexOf(row) !== -1 &&
                              slotListPerItem[row.id] && (
                                <>
                                  {slotListPerItem[row.id].map(
                                    (slot, index) => (
                                      <Grid
                                        item
                                        key={index}
                                        sm={2}
                                        md={1}
                                        sx={{ marginY: 0.5 }}
                                      >
                                        <Button
                                          key={index}
                                          variant={
                                            selectedSlot[row.id] === slot.id
                                              ? "contained"
                                              : "outlined"
                                          }
                                          onClick={() =>
                                            handleSlotSelection(row.id, slot.id)
                                          }
                                          sx={{ padding: 1 }}
                                          disabled={slot.isBooked}
                                        >
                                          {slot.time}
                                        </Button>
                                      </Grid>
                                    )
                                  )}
                                </>
                              )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
            </Grid>
          </>
        ) : (
          <Grid item sm={12} sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={100} />
            </Stack>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid>
        <LoadingButton
          variant="contained"
          onClick={() => {
            addInvestigations();
          }}
          sx={{ margin: 1 }}
          loading={submitting}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export default ScanItemsForm;
