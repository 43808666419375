import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useInvestigationItemsMasterService = () => {
  const axiosAuth = useAxiosAuth();
  const getInvestigationMasterItemList = async (type) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItemsMasters?labType=${type}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getInvestigationMasterItemById = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/InvestigationItemsMasters/${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addInvestigationMasterItem = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/InvestigationItemsMasters`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateInvestigationMasterItem = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/InvestigationItemsMasters?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteInvestigationMasterItem = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/InvestigationItemsMasters?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getInvestigationMasterItemList,
    getInvestigationMasterItemById,
    addInvestigationMasterItem,
    updateInvestigationMasterItem,
    deleteInvestigationMasterItem,
  };
};

export default useInvestigationItemsMasterService;
