// assets
import { IconUsers, IconBed, IconTransferOut } from "@tabler/icons";

// constant
const icons = {
  IconUsers,
  IconBed,
  IconTransferOut,
};

// ==============================|| IPDSTAFF MENU ITEMS ||============================== //

const ipdStaffMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "patients",
        title: "Patients",
        type: "item",
        url: "/patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },

  {
    id: "ipd-main-pages",
    type: "group",
    children: [
      {
        id: "daycare-overview",
        title: "Day Care Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/daycare-overview",
        breadcrumbs: false,
      },
      {
        id: "ipd-overview",
        title: "IPD Overview",
        icon: icons.IconBed,
        type: "item",
        url: "/ipd-overview",
        breadcrumbs: false,
      },
      {
        id: "discharge-cards",
        title: "Discharge Cards",
        type: "item",
        icon: icons.IconTransferOut,
        url: "/discharge-cards",
        breadcrumbs: false,
      },
    ],
  },
];

export default ipdStaffMenuItems;
