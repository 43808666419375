import React from "react";
import { Controller } from "react-hook-form";
import { InputAdornment, TextField, Typography } from "@mui/material";
const FormTextInput = (props) => {
  const {
    name,
    control,
    value,
    label,
    type,
    size,
    fullWidth,
    placeholder,
    disabled,
    error,
    adornment,
  } = props;
  const defaultType = "text";

  return (
    <>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <>
                {" "}
                <TextField
                  {...field}
                  label={label}
                  type={type ? type : defaultType}
                  size={size}
                  placeholder={placeholder ? placeholder : ""}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error?.[name]}
                  helperText={error?.[name]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {adornment ? adornment : ""}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default FormTextInput;
