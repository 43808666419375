import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  LinearProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import ReportForm from "./ReportForm";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import config from "config";
import { useTheme } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import usePatientReportService from "services/usePatientReportService";
import NoResult from "../../../assets/images/noresults.png";
import { useSelector } from "react-redux";
import { BASE_URL } from "services/apiBase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ExternalReport from "./external-reports";
import DoctorsReport from "./doctores-reports";
import { DialogContextProvider } from "./doctores-reports/DialogContext";
import InvestigationsRecords from "./investigation-records";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Report = (props) => {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  let patientId = searchParams.get("patientId");
  const theme = useTheme();
  const { getReportByPatientId, deleteReport, uploadReport } =
    usePatientReportService();
  const { patient } = props;
  const [openToast, setOpenToast] = React.useState(false);
  const [openDeleteToast, setOpenDeleteToast] = React.useState(false);
  const [uploading, setUploading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [patientReport, setPatientReport] = useState();
  const [reportFile, setReportFile] = useState();
  const [selectedReport, setSelectedReport] = useState({
    id: 0,
    patientId: 0,
    title: "",
    description: "",
    date: new Date().toISOString().split("T")[0],
    filePath: "",
    fileType: "",
  });

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  const handleCloseDeleteToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDeleteToast(false);
  };

  const handleCancel = () => {
    setOpenForm(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };
  const handleAdd = () => {
    setSelectedReport({
      id: 0,
      patientId: 0,
      title: "",
      description: "",
      date: new Date().toISOString().split("T")[0],
      filePath: "",
      fileType: "",
    });
    setOpenForm(true);
  };
  const handleDelete = () => {
    deleteReport(selectedReport.id)
      .then((response) => {
        setOpenDeleteToast(true);
        handleSave();
        setOpenDelete(false);
      })
      .catch((error) => {
        console.log(error);
        setOpenDelete(false);
      });
  };

  const getPatientReport = () => {
    getReportByPatientId(patient != undefined ? patient?.id : patientId)
      .then((response) => {
        if (response.status === 200) {
          setPatientReport(response.data.patientReports);
        } else {
          // console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getPatientReport();
  }, [reportFile]);

  const handleSave = () => {
    getPatientReport();
  };

  useEffect(() => {
    if (reportFile === undefined) {
      return;
    }
    setUploading(true);
    const formData = new FormData();

    formData.append("file", reportFile, reportFile.name);
    uploadReport(selectedReport.id, formData)
      .then((response) => {
        if (response.status === 200) {
          setOpenToast(true);
          getPatientReport();
          setUploading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setUploading(false);
      });
  }, [reportFile]);

  const viewReport = (filePath) => {
    window.open(BASE_URL + "/documents/" + filePath, "_blank");
  };

  return (
    <>
      {patient != undefined ? (
        <>
          {" "}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Grid item sm={6} xs={6}>
              <Typography align="left" variant="h4" color="secondary">
                Patient Reports
              </Typography>
            </Grid>

            <Grid item align="right" sm={6} xs={6}>
              <Button onClick={handleAdd} variant="contained">
                Add New Report
              </Button>
            </Grid>
          </Grid>
          {patientReport != undefined || patientReport != null ? (
            <>
              {patientReport.length == 0 ? (
                <>
                  <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                    <img src={NoResult} alt="No Result" width="150px" />
                    <Typography variant="h4" fontWeight={100}>
                      There are no reports uploaded for this patient!
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  {patientReport?.map((data, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Card
                        variant="outlined"
                        sx={{
                          padding: 2,
                          backgroundColor: theme.palette.primary.light,
                        }}
                      >
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <Typography fontWeight="bold">
                              {data.title}
                            </Typography>
                            <Typography>{data.description}</Typography>
                          </Stack>
                          <Stack spacing={1}>
                            <Typography fontWeight="bold">
                              Report Date
                            </Typography>
                            <Typography>
                              {new Date(data.date).toLocaleDateString("hi-IN")}{" "}
                            </Typography>
                          </Stack>
                          {uploading ? (
                            <Stack spacing={1}>
                              <Typography>Uploading file... </Typography>
                              <LinearProgress />
                            </Stack>
                          ) : (
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <>
                                <label htmlFor="btn-upload-1">
                                  <input
                                    id="btn-upload-1"
                                    name="btn-upload-1"
                                    style={{ display: "none" }}
                                    type="file"
                                    multiple={false}
                                    accept="application/pdf, image/png, image/jpg, image/jpeg"
                                    onChange={(e) => {
                                      setSelectedReport(data);
                                      setReportFile(e.target.files[0]);
                                    }}
                                  />

                                  <Tooltip
                                    title={
                                      data.isFileUploaded
                                        ? "Change Report File"
                                        : "Upload Report"
                                    }
                                  >
                                    <IconButton
                                      component="span"
                                      color="primary"
                                    >
                                      <UploadFileIcon />
                                    </IconButton>
                                  </Tooltip>
                                </label>
                                <Tooltip title="View Report">
                                  <IconButton
                                    // disabled={data.isFileUploaded == false ? true : false}
                                    onClick={() => {
                                      viewReport(data.filePath);
                                    }}
                                    color="primary"
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit Report Details">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedReport(data);
                                      setOpenForm(true);
                                    }}
                                    color="primary"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedReport(data);
                                      setOpenDelete(true);
                                    }}
                                    color="error"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            </Stack>
                          )}
                        </Stack>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          ) : (
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
            </Grid>
          )}
          {/* external report tabel*/}
          {role == 2 || role == 8 ? null : <ExternalReport />}
          {role == 2 || role == 8 ? null : (
            <InvestigationsRecords patient={patient} />
          )}
          <DialogContextProvider>
            {role == 2 || role == 8 ? null : <DoctorsReport />}
          </DialogContextProvider>
          <Dialog open={openForm} onClose={handleCancel} fullWidth>
            <DialogContent>
              <ReportForm
                onSave={handleSave}
                onClose={handleCancel}
                onCancel={handleCancel}
                patientId={patient != undefined ? patient?.id : patientId}
                selectedReportData={selectedReport}
              />
            </DialogContent>
          </Dialog>
          <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
            <DialogTitle>Delete Report</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the Report for{" "}
                <span style={{ color: "#E90000" }}>{selectedReport.title}</span>
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCancelDelete} variant="outlined">
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={openToast}
            autoHideDuration={6000}
            onClose={handleCloseToast}
          >
            <Alert
              onClose={handleCloseToast}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: theme.palette.success.dark,
              }}
            >
              File uploaded succesfully!!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openDeleteToast}
            autoHideDuration={6000}
            onClose={handleCloseDeleteToast}
          >
            <Alert
              onClose={handleCloseDeleteToast}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: theme.palette.success.dark,
              }}
            >
              Report deleted succesfully!!
            </Alert>
          </Snackbar>
        </>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Grid item sm={6} xs={6}>
              <Typography align="left" variant="h4" color="secondary">
                Patient Reports
              </Typography>
            </Grid>

            <Grid item align="right" sm={6} xs={6}>
              <Button onClick={handleAdd} variant="contained">
                Add New Report
              </Button>
              {/* <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  navigate(-1);
                }}
                variant="outlined"
                color="error"
                sx={{ margin: 1 }}
              >
                Back
              </Button> */}
            </Grid>
          </Grid>
          {patientReport != undefined || patientReport != null ? (
            <>
              {patientReport.length == 0 ? (
                <>
                  <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                    <img src={NoResult} alt="No Result" width="150px" />
                    <Typography variant="h4" fontWeight={100}>
                      There are no reports uploaded for this patient!
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  {patientReport?.map((data, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <Card
                        variant="outlined"
                        sx={{
                          padding: 2,
                          backgroundColor: theme.palette.primary.light,
                        }}
                      >
                        <Stack spacing={2}>
                          <Stack spacing={1}>
                            <Typography fontWeight="bold">
                              {data.title}
                            </Typography>
                            <Typography>{data.description}</Typography>
                          </Stack>
                          <Stack spacing={1}>
                            <Typography fontWeight="bold">
                              Report Date
                            </Typography>
                            <Typography>
                              {new Date(data.date).toLocaleDateString("hi-IN")}{" "}
                            </Typography>
                          </Stack>
                          {uploading ? (
                            <Stack spacing={1}>
                              <Typography>Uploading file... </Typography>
                              <LinearProgress />
                            </Stack>
                          ) : (
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <>
                                <label htmlFor="btn-upload-1">
                                  <input
                                    id="btn-upload-1"
                                    name="btn-upload-1"
                                    style={{ display: "none" }}
                                    type="file"
                                    multiple={false}
                                    accept="application/pdf, image/png, image/jpg, image/jpeg"
                                    onChange={(e) => {
                                      setSelectedReport(data);
                                      setReportFile(e.target.files[0]);
                                    }}
                                  />

                                  <Tooltip
                                    title={
                                      data.isFileUploaded
                                        ? "Change Report File"
                                        : "Upload Report"
                                    }
                                  >
                                    <IconButton
                                      component="span"
                                      color="primary"
                                    >
                                      <UploadFileIcon />
                                    </IconButton>
                                  </Tooltip>
                                </label>
                                <Tooltip title="View Report">
                                  <IconButton
                                    // disabled={data.isFileUploaded == false ? true : false}
                                    onClick={() => {
                                      viewReport(data.filePath);
                                    }}
                                    color="primary"
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit Report Details">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedReport(data);
                                      setOpenForm(true);
                                    }}
                                    color="primary"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      setSelectedReport(data);
                                      setOpenDelete(true);
                                    }}
                                    color="error"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            </Stack>
                          )}
                        </Stack>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          ) : (
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="rounded" width={"100%"} height={200} />
              </Grid>
            </Grid>
          )}
          {/* external report tabel*/}

          {role == 2 || role == 8 ? null : <ExternalReport />}
          {role == 2 || role == 8 ? null : (
            <InvestigationsRecords patient={patient} />
          )}

          <DialogContextProvider>
            {role == 2 || role == 8 ? null : <DoctorsReport />}
          </DialogContextProvider>

          <Dialog open={openForm} onClose={handleCancel} fullWidth>
            <DialogContent>
              <ReportForm
                onSave={handleSave}
                onClose={handleCancel}
                onCancel={handleCancel}
                patientId={patient != undefined ? patient?.id : patientId}
                selectedReportData={selectedReport}
              />
            </DialogContent>
          </Dialog>
          <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
            <DialogTitle>Delete Report</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the Report for{" "}
                <span style={{ color: "#E90000" }}>{selectedReport.title}</span>
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCancelDelete} variant="outlined">
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={openToast}
            autoHideDuration={6000}
            onClose={handleCloseToast}
          >
            <Alert
              onClose={handleCloseToast}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: theme.palette.success.dark,
              }}
            >
              File uploaded succesfully!!
            </Alert>
          </Snackbar>

          <Snackbar
            open={openDeleteToast}
            autoHideDuration={6000}
            onClose={handleCloseDeleteToast}
          >
            <Alert
              onClose={handleCloseDeleteToast}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: theme.palette.success.dark,
              }}
            >
              Report deleted succesfully!!
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default Report;
