import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useHysterolaproscopyPhotosServices = () => {
  const axiosAuth = useAxiosAuth();

  const addHysterolaproscopyReportPhoto = async (id, fd) => {
    try {
      const response = await axiosAuth.post(
        `/api/HysterolaproscopyPhotos/upload-hysterolaproscopy-photo?hysterolaproscopyId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getHysterolaproscopyPhotoByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/HysterolaproscopyPhotos/by-hysterolaproscopy-id?hysterolaproscopyId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteHysterolaproscopyPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/HysterolaproscopyPhotos?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addHysterolaproscopyReportPhoto,
    getHysterolaproscopyPhotoByScanReportId,
    deleteHysterolaproscopyPhoto,
  };
};
export default useHysterolaproscopyPhotosServices;
