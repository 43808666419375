import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Snackbar,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useGrowthAltraSoundScanService from "services/useGrowthAltrasoundScanService";
import useGrowthUltraSoundScanPhotoService from "services/useGrowthultrasoundReportPhotoServices";
import AlertMessage from "ui-component/AlertMessage";
import useSonographyReportPhotoService from "services/useSonographyReportPhoto";
import useSonographyService from "services/useSonographyReport";
import useViabilityReportPhotoService from "services/useViabilityReportPhotos";
import useViabilityReportService from "services/useViabilityReport";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const DeleteDialog = ({
  openDelete,
  handleCancelDelete,
  selectedReport,
  reportName,
  loadData,
}) => {
  const theme = useTheme();
  const { deleteGrowthAltraSoundScanRecord } = useGrowthAltraSoundScanService();
  const { deleteGrowthUltraSoundScanRecordPhoto } =
    useGrowthUltraSoundScanPhotoService();
  const { deleteSonographyPhoto } = useSonographyReportPhotoService();
  const { deleteSonographyRecord } = useSonographyService();
  const { deleteViabilityPhoto } = useViabilityReportPhotoService();
  const { deleteViabilityRecord } = useViabilityReportService();
  const [opendeleteTost, setOpenDeleteTost] = useState(false);
  useEffect(() => {
    if (opendeleteTost) {
      setTimeout(() => {
        setOpenDeleteTost(false);
      }, 500);
    }
  }, [openDelete]);
  const handleDelete = () => {
    // console.log("reportNAme", reportName);
    // setOpenDeleteTost(false);
    // if (reportName.toLowerCase().split(" ")[0] === "growth") {
    //   deleteGrowthAltraSoundScanRecord(selectedReport.id)
    //     .then((result) => {
    //       loadData();
    //       handleCancelDelete();
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // } else if (reportName.toLowerCase() == "sonography") {
    deleteSonographyRecord(selectedReport.id)
      .then((result) => {
        loadData();
        handleCancelDelete();
      })
      .catch((err) => {
        console.log("err", err);
      });
    // } else if (reportName.toLowerCase() == "viability") {
    //   deleteViabilityRecord(selectedReport.id)
    //     .then((result) => {
    //       loadData();
    //       handleCancelDelete();
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // } else {
    //   console.log("errrrror");
    // }
  };

  return (
    <>
      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Report
            {/* <span style={{ color: "#E90000" }}>{` ${reportName}`} report</span>? */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <AlertMessage
        message={"Report deleted succesfully!!"}
        opendeleteTost={opendeleteTost}
      />
    </>
  );
};

export default DeleteDialog;
