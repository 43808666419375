import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Paper,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import useSonographyService from "services/useSonographyReport";
import NoReport from "ui-component/formcomponent/NoReport";
import { convertDateToLocal } from "utils/DateOperations";

const useStyles = makeStyles({
  fix: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "white", // Set background color to match your table's background
  },
});

const Comparison = ({ patientId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const { getSonographyByPatientId } = useSonographyService();
  const [sonographyData, setSonographyData] = useState();

  const gatherAllSonographyData = () => {
    getSonographyByPatientId(patientId, -1)
      .then((response) => {
        setSonographyData(response.data.sonographies);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    gatherAllSonographyData();
  }, []);

  return (
    <Grid container>
      <Typography variant="h4" sx={{ display: { xs: "none", sm: "block" } }}>
        Sonography Comparision
      </Typography>
      {sonographyData?.length > 0 ? (
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{ width: "100%", overflowX: "auto" }}
          >
            <Table
              size="small"
              sx={{
                border: "solid",
                borderWidth: 1,
                borderColor: "divider",
              }}
            >
              <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                <TableRow>
                  <TableCell className={classes.fix}></TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography variant="h5">{row.typeDisplay}</Typography>
                      <Typography variant="h6" color={theme.palette.grey[500]}>
                        {" "}
                        {convertDateToLocal(row.date)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Ultrasound Age</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>
                        {row.avgUltrasoundAgeWeek} wk {row.avgUltrasoundAgeDays}{" "}
                        d
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">EDD by LMP</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>
                        {convertDateToLocal(row.eddByLMP)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">EDD by USG</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>
                        {convertDateToLocal(row.eddByUSG)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Liquor</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>{row.liquorDisplay}</Typography>
                      {row.afi != 0 ? (
                        <Typography variant="subtitle2">
                          AFI-{row.afi} cm
                        </Typography>
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Estimated Fetal Weight</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      {row.estimatedFetalWeight != 0 ? (
                        <Typography>{row.estimatedFetalWeight} gm</Typography>
                      ) : (
                        <Typography>---</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Placenta</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>
                        {row.placenta1Display}, {row.placenta2Display},{" "}
                        {row.placenta3Display}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fix}>
                    <Typography variant="h5">Doppler Evaluation</Typography>
                  </TableCell>
                  {sonographyData?.map((row) => (
                    <TableCell
                      sx={{
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography>{row.dopplerEvaluationDisplay}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <NoReport />
      )}
    </Grid>
  );
};

export default Comparison;
