import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { borderRadius } from "store/constant";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import User1 from "assets/images/users/user-round.svg";
import { setUser } from "store/userSlice";
import ChangePassword from "views/change-password";

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from "@tabler/icons";
import { fontSize } from "@mui/system";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId, userName, roleDisplay } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(setUser({ userId: 0, profileId: 0, displayName: "", role: "" }));
    navigate("/login");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleCancel = () => {
    setOpenForm(false);
  };
  const handleSave = () => {
    setOpenForm(false);
  };

  return (
    <>
      {userName != undefined || userName != null ? (
        <>
          <Chip
            sx={{
              height: "48px",
              alignItems: "center",
              borderRadius: "27px",
              transition: "all .2s ease-in-out",
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.main,
                background: `${theme.palette.primary.main}!important`,
                color: theme.palette.primary.light,
                "& svg": {
                  stroke: theme.palette.primary.light,
                },
              },
              "& .MuiChip-label": {
                lineHeight: 0,
              },
            }}
            icon={
              <Avatar
                sx={{
                  ...theme.typography.mediumAvatar,
                  margin: "8px 0 8px 8px !important",
                  cursor: "pointer",
                  backgroundColor: theme.palette.secondary.main,
                }}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="secondary"
              >
                <Typography variant="h4" color={theme.palette.common.white}>
                  {userName.charAt(0)}
                </Typography>
              </Avatar>
            }
            label={
              <IconSettings
                stroke={1.5}
                size="1.5rem"
                color={theme.palette.primary.main}
              />
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
          <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 14],
                  },
                },
              ],
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={open} {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard
                      border={false}
                      elevation={16}
                      content={false}
                      boxShadow
                      shadow={theme.shadows[16]}
                    >
                      <Box sx={{ p: 1 }}>
                        <ListItem>
                          <ListItemIcon>
                            <Avatar
                              sx={{
                                mr: 2,
                                backgroundColor: theme.palette.secondary.main,
                              }}
                            >
                              <Typography
                                color={theme.palette.common.white}
                                variant="h4"
                              >
                                {userName.charAt(0)}
                              </Typography>
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={userName}
                            secondary={roleDisplay}
                            secondaryTypographyProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: 14,
                              },
                            }}
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: 500,
                                color: theme.palette.primary.dark,
                                fontSize: 16,
                              },
                            }}
                          ></ListItemText>
                        </ListItem>
                        <Divider sx={{ marginTop: 1 }} />
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 220,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={() => setOpenForm(true)}
                            disabled={false}
                          >
                            <ListItemIcon>
                              <IconSettings
                                stroke={1.5}
                                size="1.3rem"
                                color={theme.palette.grey[800]}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  Change Password
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout
                                stroke={1.5}
                                size="1.3rem"
                                color={theme.palette.grey[800]}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  color={theme.palette.grey[800]}
                                >
                                  Logout
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                        <Box sx={{ pb: 2 }}>
                          <Divider />
                        </Box>
                      </Box>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </Popper>
          <Dialog open={openForm} onClose={handleCancel} maxWidth="md">
            <DialogContent>
              <ChangePassword
                onSave={handleSave}
                onCancel={handleCancel}
                usersId={userId}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

export default ProfileSection;
