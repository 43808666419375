import React from "react";
import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useAppointmentService = () => {
  const axiosAuth = useAxiosAuth();

  const getAllIpdAppointmentsByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/all-ipd-appointments?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAppointmentsByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/list-byPatient?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAppointmentSlotBookingByDate = async (date) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/appointmentSlot-booking-by-date?date=${date}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getUpcomingAppointmentByPatientDoctorId = async (
    patientId,
    doctorId
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/upcomming-appointment?patientId=${patientId}&userId=${doctorId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAllAppointmentsByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/all-appointments-byPatient?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getLastIpdAppointmentByPatientId = async (patientId) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/last-ipd-appointment?patientId=${patientId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getAppointmentByDoctorId = async (doctorId, date, type) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/list-byDoctor?doctorId=${doctorId}&date=${date}&sortingType=${type}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getAppointmentById = async (id) => {
    try {
      const response = await axiosAuth.get(`/api/Appointments/${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const getLastAppointmentDateByPatientId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/Appointments/last-appointment-date?patientId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const updateAppointment = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/Appointments?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteAppointment = async (id) => {
    try {
      const response = await axiosAuth.delete(`/api/Appointments?id=${id}`);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const addAppointment = async (values) => {
    try {
      const response = await axiosAuth.post(`/api/Appointments`, values);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateAppointmentType = async (id, appointmentType) => {
    try {
      const response = await axiosAuth.put(
        `/api/Appointments/update-appointment-type?id=${id}&appointmentType=${appointmentType}`
      );
      return handleResponse(response);
    } catch (err) {
      return handleError(err);
    }
  };

  const updateAppointmentStatus = async (
    id,
    status,
    dischargeType,
    remark,
    date,
    time
  ) => {
    if (dischargeType === undefined) {
      dischargeType = 0;
    }
    if (remark === undefined) {
      remark = "";
    }
    if (date === undefined) {
      date = new Date().toISOString();
    }
    try {
      const response = await axiosAuth.put(
        `/api/Appointments/Update-Status?id=${id}&status=${status}&dischargeType=${dischargeType}&remarks=${remark}&date=${date}&timeOfDischarge=${time}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const updateVitals = async (
    appointmentId,
    weight,
    temperature,
    pulses,
    bp,
    height
  ) => {
    try {
      const response = await axiosAuth.post(
        `/api/Appointments/update-vitals?appointmentId=${appointmentId}&pulses=${pulses}&bp=${bp}&weight=${weight}&height=${height}&temperature=${temperature}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const updatePaymentStatus = async (id, status) => {
    try {
      const response = await axiosAuth.put(
        `/api/Appointments/update-payment-status?id=${id}&isPaid=${status}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    getAllIpdAppointmentsByPatientId,
    getAppointmentSlotBookingByDate,
    getLastIpdAppointmentByPatientId,
    getUpcomingAppointmentByPatientDoctorId,
    getAppointmentsByPatientId,
    getAppointmentById,
    getAppointmentByDoctorId,
    updateAppointment,
    deleteAppointment,
    addAppointment,
    updateAppointmentStatus,
    updateVitals,
    getAllAppointmentsByPatientId,
    getLastAppointmentDateByPatientId,
    updatePaymentStatus,
    updateAppointmentType,
  };
};

export default useAppointmentService;
