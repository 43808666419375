import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const usePatientAbortionHistoryService = () => {
  const axiosAuth = useAxiosAuth();
  const getAbortionHistoryByAntenatalRecordId = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientAbortionHistories/list-byPatientAntenatalRecord?patientAntenatalRecordId=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAllAbortionHistory = async () => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientAbortionHistories/list`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getAbortionHistoryById = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/PatientAbortionHistories?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const addAbortionHistory = async (values) => {
    try {
      const response = await axiosAuth.post(
        `/api/PatientAbortionHistories`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const updateAbortionHistory = async (id, values) => {
    try {
      const response = await axiosAuth.put(
        `/api/PatientAbortionHistories?id=${id}`,
        values
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const deleteAbortionHistory = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/PatientAbortionHistories?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    getAbortionHistoryByAntenatalRecordId,
    getAllAbortionHistory,
    getAbortionHistoryById,
    addAbortionHistory,
    updateAbortionHistory,
    deleteAbortionHistory,
  };
};

export default usePatientAbortionHistoryService;
