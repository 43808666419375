import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { useSearchParams } from "react-router-dom";

//MUI Imports
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  CardContent,
  TextField,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

//Project Imports
import MainCard from "ui-component/cards/MainCard";
import useInvestigationItemService from "services/useInvestigationItemService";
import usePatientsService from "services/usePatientsService";
import Report from "views/patients/patient-details/Report";
import calculateAge from "utils/calculateAge";
import InvestigationsPrint from "./InvestigationsPrint";

//Icon Imports
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import PrintIcon from "@mui/icons-material/Print";
import CommentIcon from "@mui/icons-material/Comment";
import AddIcon from "@mui/icons-material/Add";

//Third Party Imports
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import SuggestInvestigationsForm from "views/patients/patient-details/SuggestInvestigationsForm";
import ScanItemsForm from "views/patients/patient-details/ScanItemsForm";
import InvestigationAntenatalRecord from "./InvestigationAntenatalRecord";
import InvestigationMenstrualHistory from "./InvestigationMenstrualHistory";

const InvestigationDetails = () => {
  const theme = useTheme();

  const location = useLocation();
  const { state } = location;

  const {
    getInvestigationItemsListByPatientAndStatus,
    statusUpdateInvestigationItems,
    updateInvestigationItems,
    getInvestigationItemsListByLabType,
    deleteInvestigationItems,
  } = useInvestigationItemService();
  const { getPatientById } = usePatientsService();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userId, userName, role, displayRole, profileId } = useSelector(
    (state) => state.userReducer
  );

  const type = 0;
  const status = -1;

  //States
  const [tabValue, setTabValue] = useState(0);

  const [openPaidDialog, setOpenPaidDialog] = useState(false);
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const [investigationList, setInvestigationList] = useState();
  const [patientData, setPatientData] = useState();
  const [selectedInvestigation, setSelectedInvestigation] = useState();
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [doctorName, setDoctorName] = useState("---");

  const componentRef = useRef();
  const [printCmd, setPrintCmd] = useState();

  //! States for investigations
  const [suggestedInvestigationForm, setSuggestedInvestigationForm] =
    useState(false);

  //Methods
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSuggestedInvestigation = () => {
    setSuggestedInvestigationForm(false);
    //getAppointmentmentDetails();
  };

  const onPrintComplete = () => {
    setPrintCmd(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onPrintComplete,
  });

  const calculateTotal = () => {
    if (investigationList != undefined || investigationList != null) {
      setTotalAmount(
        investigationList.reduce(
          (amount, charges) => amount + charges.amount,
          0
        )
      );
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const handleDeleteInvestigation = () => {
    if (selectedInvestigation) {
      deleteInvestigationItems(selectedInvestigation.id).then((response) => {
        if (response.status == 200) {
          setOpenDeleteDialog(false);
          getInvestigationList();
        }
      });
    }
  };

  const getInvestigationList = () => {
    let labType = 0;
    if (role == 2) {
      labType = 0;
    } else if (role == 8) {
      labType = 1;
    } else {
      labType = -1;
    }
    getInvestigationItemsListByPatientAndStatus(
      searchParams.get("Id"),
      status,
      labType,
      type,
      searchParams.get("date")
    )
      .then((response) => {
        if (response.status === 200) {
          setDoctorName(response.data.doctorName);
          if (role === 2) {
            let data = response.data.investigationItems.filter((item) => {
              return item.status != 0;
            });
            setInvestigationList(response.data.investigationItems);
          } else {
            setInvestigationList(response.data.investigationItems);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPatientDetails = () => {
    getPatientById(searchParams.get("Id"))
      .then((response) => {
        if (response.status === 200) {
          setPatientData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeStatus = (id, status) => {
    statusUpdateInvestigationItems(id, status, userId, paymentAmount)
      .then((response) => {
        if (response.status === 200) {
          setOpenToast(true);
          setOpenPaidDialog(false);
          setOpenCompleteDialog(false);
          setOpenDeleteDialog(false);
          getInvestigationList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePaidCancel = () => {
    setOpenPaidDialog(false);
  };

  const handleCommentCancel = () => {
    setOpenCommentDialog(false);
  };

  const handleCompleteCancel = () => {
    setOpenCompleteDialog(false);
  };

  const handleCancelCancel = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    getInvestigationList();
    getPatientDetails();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [investigationList]);

  useEffect(() => {
    if (printCmd) {
      handlePrint();
    }
  });

  useEffect(() => {
    setComment(selectedInvestigation?.comment);
  }, [selectedInvestigation]);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Investigation Details"></Tab>
              <Tab
                label="Antenatal Records"
                sx={{ display: role == 8 ? "flex" : "none" }}
              ></Tab>
              <Tab
                label="Menstrual History"
                sx={{ display: role == 8 ? "flex" : "none" }}
              ></Tab>
            </Tabs>
          }
          action={
            <Grid container>
              <Grid
                item
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                {tabValue === 0 ? (
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      getInvestigationList();
                      getPatientDetails();
                    }}
                    sx={{ marginLeft: 2 }}
                  >
                    Refresh
                  </Button>
                ) : null}

                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginLeft: 2 }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              align="right"
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "none" },
              }}
            >
              <Stack direction="row">
                {tabValue === 0 ? (
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                      getInvestigationList();
                      getPatientDetails();
                    }}
                    sx={{ marginLeft: 2 }}
                  >
                    Refresh
                  </Button>
                ) : null}

                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ marginLeft: 2 }}
                >
                  Back
                </Button>
              </Stack>
            </Grid>
            <>
              {tabValue == 0 && (
                <>
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    Investigation Details
                  </Typography>
                  <Grid item sm={12}>
                    {patientData != null || patientData != undefined ? (
                      <>
                        <Grid
                          container
                          direction="row"
                          sx={{ paddingX: 1, marginBottom: 2 }}
                        >
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              UHID
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              : {patientData.uhid}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Patient Name
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              : {patientData.patientMaster.firstName}{" "}
                              {patientData.patientMaster.middleName}{" "}
                              {patientData.patientMaster.lastName}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Patient Age
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              :{" "}
                              {calculateAge(
                                patientData.patientMaster.birthDate
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Patient Gender
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              : {patientData.patientMaster.genderDisplay}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Patient Address
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              : {patientData.patientMaster.address},{" "}
                              {patientData.patientMaster.city}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Mobile No.
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography sx={{ mb: "3px" }}>
                              : {patientData.patientMaster.mobileNumber}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} align="left">
                            <Typography variant="h5" sx={{ mb: "3px" }}>
                              Doctor's Name
                            </Typography>
                          </Grid>
                          <Grid item xs={9} sm={9} align="left">
                            <Typography
                              sx={{ mb: "3px" }}
                              variant="h5"
                              color="primary.dark"
                            >
                              : {doctorName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <div style={{ display: "none" }}>
                      <InvestigationsPrint
                        ref={componentRef}
                        patientData={patientData}
                        investigationData={investigationList}
                        doctorName={doctorName}
                        totalAmount={totalAmount}
                      />
                    </div>
                  </Grid>
                  <Grid item align="right" sm={12} sx={{ mb: 2 }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSuggestedInvestigationForm(true);
                      }}
                    >
                      Add Investigations
                    </Button>
                  </Grid>
                  {investigationList != undefined ||
                  investigationList != null ? (
                    <>
                      {investigationList.length > 0 ? (
                        <>
                          <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <TableContainer>
                              <Table
                                sx={{
                                  border: "solid",
                                  borderWidth: 1,
                                  borderColor: "divider",
                                }}
                              >
                                <TableHead
                                  sx={{
                                    backgroundColor:
                                      theme.palette.primary.light,
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Investigation Name</TableCell>
                                    <TableCell>Investigation Date</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Charges</TableCell>

                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {investigationList?.map((row, index) => (
                                    <TableRow key={row.id}>
                                      <TableCell component="th" scope="row">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {new Date(
                                          row.createDate
                                        ).toLocaleDateString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.typeDisplay}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.amount}
                                      </TableCell>

                                      <TableCell align="left">
                                        {row.status === 0 && (
                                          <Typography
                                            variant="h5"
                                            color="error"
                                          >
                                            {row.statusDisplay}
                                          </Typography>
                                        )}

                                        {row.status === 2 && (
                                          <Stack>
                                            <Typography
                                              variant="h5"
                                              color="success.dark"
                                            >
                                              {row.statusDisplay}
                                            </Typography>
                                            <Typography variant="caption">
                                              Completed By:{" "}
                                              {row.completedByUserName}
                                            </Typography>
                                            <Typography variant="caption">
                                              On:{" "}
                                              {new Date(
                                                row.completionDate
                                              ).toLocaleDateString("hi-IN")}
                                            </Typography>
                                          </Stack>
                                        )}
                                        {row.status === 3 && (
                                          <Typography
                                            variant="h5"
                                            color="secondary"
                                          >
                                            {row.statusDisplay}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        <>
                                          {row.status === 0 && (
                                            <Button
                                              onClick={() => {
                                                setSelectedInvestigation(row);
                                                setOpenCompleteDialog(true);
                                              }}
                                              size="small"
                                              variant="contained"
                                            >
                                              Mark As Complete
                                            </Button>
                                          )}
                                          {row.status === 2 && (
                                            <Button
                                              onClick={() => {
                                                setSelectedInvestigation(row);
                                                setOpenCompleteDialog(true);
                                              }}
                                              size="small"
                                              variant="outlined"
                                            >
                                              Undo Complete
                                            </Button>
                                          )}
                                        </>

                                        <>
                                          {row.status === 0 && (
                                            <>
                                              <Button
                                                onClick={() => {
                                                  setSelectedInvestigation(row);
                                                  setOpenDeleteDialog(true);
                                                }}
                                                color="error"
                                                size="small"
                                                sx={{ marginLeft: 1 }}
                                                variant="outlined"
                                              >
                                                Delete
                                              </Button>
                                            </>
                                          )}
                                        </>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>

                          <Box sx={{ display: { xs: "block", md: "none" } }}>
                            {investigationList?.map((row, index) => (
                              <Card
                                sx={{ padding: 2, marginTop: 2 }}
                                variant="outlined"
                                key={index}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography variant="h5">Name</Typography>
                                  <Stack>
                                    <Typography>{row.name} </Typography>
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ marginTop: 1 }}
                                >
                                  <Typography variant="h5">
                                    Created On:
                                  </Typography>
                                  <Typography>
                                    {new Date(
                                      row.createDate
                                    ).toLocaleDateString()}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ marginTop: 1 }}
                                >
                                  <Typography variant="h5">Type</Typography>
                                  <Typography>{row.typeDisplay}</Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ marginTop: 1 }}
                                >
                                  <Typography variant="h5">Charges</Typography>
                                  <Typography>{row.amount}</Typography>
                                </Stack>

                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ marginTop: 1 }}
                                >
                                  <Typography variant="h5">Status</Typography>
                                  <Grid>
                                    {" "}
                                    {row.status === 0 && (
                                      <Typography variant="h5" color="error">
                                        {row.statusDisplay}
                                      </Typography>
                                    )}
                                    {row.status === 2 && (
                                      <Stack>
                                        <Typography
                                          variant="h5"
                                          color="success.dark"
                                        >
                                          {row.statusDisplay}
                                        </Typography>
                                        <Typography variant="caption">
                                          Completed By:{" "}
                                          {row.completedByUserName}
                                        </Typography>
                                        <Typography variant="caption">
                                          On:{" "}
                                          {new Date(
                                            row.completionDate
                                          ).toLocaleDateString("hi-IN")}
                                        </Typography>
                                      </Stack>
                                    )}
                                    {row.status === 3 && (
                                      <Typography
                                        variant="h5"
                                        color="secondary"
                                      >
                                        {row.statusDisplay}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Stack>
                                <Divider sx={{ marginTop: 1 }} />
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  sx={{ marginTop: 1 }}
                                  spacing={2}
                                >
                                  {row.status === 0 && (
                                    <Button
                                      onClick={() => {
                                        setSelectedInvestigation(row);
                                        setOpenCompleteDialog(true);
                                      }}
                                      size="small"
                                      variant="contained"
                                    >
                                      Mark As Complete
                                    </Button>
                                  )}
                                  {row.status === 2 && (
                                    <Button
                                      onClick={() => {
                                        setSelectedInvestigation(row);
                                        setOpenCompleteDialog(true);
                                      }}
                                      size="small"
                                      variant="outlined"
                                    >
                                      Undo Complete
                                    </Button>
                                  )}

                                  {row.status === 0 && (
                                    <>
                                      <Button
                                        onClick={() => {
                                          setSelectedInvestigation(row);
                                          setOpenDeleteDialog(true);
                                        }}
                                        size="small"
                                        sx={{ marginLeft: 1 }}
                                        variant="outlined"
                                        color="error"
                                      >
                                        Delete
                                      </Button>
                                    </>
                                  )}
                                </Stack>
                              </Card>
                            ))}
                          </Box>
                          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="h3" sx={{ mb: "3px" }}>
                                Total Amount:
                              </Typography>
                              <Typography
                                variant="h3"
                                color="primary.main"
                                sx={{ mb: "3px" }}
                              >
                                ₹ {totalAmount}
                              </Typography>
                            </Stack>
                          </Grid>
                          {investigationList.length < 1 ? (
                            <>
                              <Grid align="center" sx={{ padding: 5 }}>
                                <Typography variant="h5">
                                  No Investigations Found...
                                </Typography>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <Typography
                          sx={{ textAlign: "center", variant: "h4", margin: 4 }}
                        >
                          There are no internal investigations in the list.
                        </Typography>
                      )}
                    </>
                  ) : null}
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ marginY: 5 }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {" "}
                    {patientData != null || patientData != undefined ? (
                      <Report patient={patientData} />
                    ) : null}
                  </Grid>
                </>
              )}
              {tabValue == 1 && (
                <>
                  <InvestigationAntenatalRecord patientData={patientData} />
                </>
              )}
              {tabValue == 2 && (
                <>
                  <InvestigationMenstrualHistory patientData={patientData} />
                </>
              )}
            </>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        open={openCompleteDialog}
        onClose={handleCompleteCancel}
        fullWidth
      >
        <DialogTitle fontSize={16}>Change Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedInvestigation?.status === 0
              ? "Are you sure you want to mark this investigation as complete?"
              : "Are you sure you want to undo the complete action?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (selectedInvestigation?.status === 0) {
                changeStatus(selectedInvestigation?.id, 2);
              } else {
                changeStatus(selectedInvestigation?.id, 0);
              }
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button onClick={handleCompleteCancel} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCancelCancel} fullWidth>
        <DialogTitle fontSize={16}>Delete Investigation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this investigation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteInvestigation();
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button onClick={handleCancelCancel} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Investigation Form */}
      {investigationList != undefined && (
        <Dialog
          open={suggestedInvestigationForm}
          onClose={handleCloseSuggestedInvestigation}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            {role == 2 ? (
              <SuggestInvestigationsForm
                onClose={handleCloseSuggestedInvestigation}
                appointmentId={investigationList[0]?.appointmentId}
                patientId={searchParams.get("Id")}
                investigationItems={investigationList}
                onSave={getInvestigationList}
              />
            ) : (
              <ScanItemsForm
                onClose={handleCloseSuggestedInvestigation}
                appointmentId={investigationList[0]?.appointmentId}
                patientId={searchParams.get("Id")}
                investigationItems={investigationList}
                onSave={getInvestigationList}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: theme.palette.success.dark,
          }}
        >
          Data updated succesfully!!
        </Alert>
      </Snackbar>
    </>
  );
};

export default InvestigationDetails;
