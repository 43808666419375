import useAxiosAuth from "../hooks/useAxiosAuth";
import { handleResponse, handleError } from "./response";

const useGrowthUltraSoundScanPhotoService = () => {
  const axiosAuth = useAxiosAuth();

  const addGrowthAltraSoundScanReportPhoto = async (id, fd) => {
    try {
      const response = await axiosAuth.post(
        `/api/GrowthUltrasoundScanPhotos/upload-growthultrasoundscan-photo?growthUltrasoundScanId=${id}`,
        fd
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getById = async (id) => {
    try {
      const response = await axiosAuth.get(
        `/api/GrowthUltrasoundScanPhotos?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  const getByScanReportId = async (reportId) => {
    try {
      const response = await axiosAuth.get(
        `/api/GrowthUltrasoundScanPhotos/by-growthultrasoundscan-id?growthUltrasoundScanId=${reportId}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };

  const deleteGrowthUltraSoundScanRecordPhoto = async (id) => {
    try {
      const response = await axiosAuth.delete(
        `/api/GrowthUltrasoundScanPhotos?id=${id}`
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  };
  return {
    addGrowthAltraSoundScanReportPhoto,
    deleteGrowthUltraSoundScanRecordPhoto,
    getById,
    getByScanReportId,
  };
};
export default useGrowthUltraSoundScanPhotoService;
