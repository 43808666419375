import React from "react";
import { Grid, Typography, Stack, Button } from "@mui/material";
import ReportCard from "../ReportCard";
import NoReport from "ui-component/formcomponent/NoReport";
const ThirdTrimester = ({
  thirdTrimesterData,
  loadThirdTrimesterData,
  thirdTrimesterReport,
  setThirdTrimesterReport,
}) => {
  return (
    <Grid xs={12}>
      <Typography variant="h4" color="primary">
        Third trimester
      </Typography>
      <Stack mt={2} columnGap={2} direction="row">
        <Button
          variant={thirdTrimesterReport == 5 ? "contained" : "outlined"}
          onClick={() => {
            setThirdTrimesterReport(5);
          }}
        >
          Colour Doppler
        </Button>
      </Stack>
      {Array.isArray(thirdTrimesterData) && thirdTrimesterData.length > 0 ? (
        <ReportCard
          reportData={thirdTrimesterData}
          loadData={loadThirdTrimesterData}
        />
      ) : (
        <>
          <NoReport />
        </>
      )}
    </Grid>
  );
};

export default ThirdTrimester;
