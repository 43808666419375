// assets
import {
  IconDashboard,
  IconBrandChrome,
  IconHelp,
  IconUsers,
  IconUser,
  IconRoute,
  IconLayoutKanban,
  IconReport,
  IconSettings,
  IconBed,
  IconFileInvoice,
  IconCertificate,
  IconCalendarTime,
  IconReportSearch,
  IconCurrencyRupee,
  IconTestPipe,
  IconTransferOut,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconBrandChrome,
  IconHelp,
  IconUsers,
  IconUser,
  IconRoute,
  IconLayoutKanban,
  IconReport,
  IconSettings,
  IconBed,
  IconFileInvoice,
  IconCurrencyRupee,
  IconCertificate,
  IconCalendarTime,
  IconReportSearch,
  IconTestPipe,
  IconTransferOut,
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const doctorMenuItems = [
  {
    id: "patients-section",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/dashboard",
        icon: icons.IconDashboard,
        breadcrumbs: false,
      },
      {
        id: "patients",
        title: "Patients",
        type: "item",
        url: "/patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
      {
        id: "filtered-patients",
        title: "Filtered Patients",
        type: "item",
        url: "/filtered-patients",
        icon: icons.IconUsers,
        breadcrumbs: false,
      },
    ],
  },

  {
    id: "doctor-main-pages",
    type: "group",
    children: [
      {
        id: "my-appointments",
        title: "My Appointments",
        type: "item",
        icon: icons.IconCalendarTime,
        url: "/my-appointments",
        breadcrumbs: false,
      },
      {
        id: "daycare-overview",
        title: "Day Care Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/daycare-overview",
        breadcrumbs: false,
      },
      {
        id: "ipd-overview",
        title: "IPD Overview",
        type: "item",
        icon: icons.IconBed,
        url: "/ipd-overview",
        breadcrumbs: false,
      },
      {
        id: "billing",
        title: "Billing",
        type: "collapse",
        icon: icons.IconFileInvoice,
        breadcrumbs: false,
        children: [
          {
            id: "opdReceipts",
            title: "OPD Receipts",
            type: "item",
            url: "/opd-receipts",
            breadcrumbs: false,
          },
          {
            id: "additional-treatment-billing",
            title: "Additional Treatment Bills",
            type: "item",
            url: "/additional-treatment-billing",
            breadcrumbs: false,
          },
          {
            id: "investigation-billing",
            title: "Investigation Bills",
            type: "item",
            url: "/investigation-billing",
            breadcrumbs: false,
          },
          {
            id: "scan-billing",
            title: "Scan Bills",
            type: "item",
            url: "/scan-billing",
            breadcrumbs: false,
          },
          {
            id: "advance-payments",
            title: "Advance Payments",
            type: "item",
            url: "/advance-payments",
            breadcrumbs: false,
          },
          {
            id: "ipd-billing",
            title: "IPD Bills",
            type: "item",
            url: "/billing",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "discharge-cards",
        title: "Discharge Cards",
        type: "item",
        icon: icons.IconTransferOut,
        url: "/discharge-cards",
        breadcrumbs: false,
      },
      {
        id: "operating-expenses",
        title: "Expenses",
        type: "item",
        icon: icons.IconCurrencyRupee,
        url: "/operating-expenses",
        breadcrumbs: false,
      },
      {
        id: "certificates",
        title: "Certificates",
        type: "item",
        url: "/certificates",
        icon: icons.IconCertificate,
        breadcrumbs: false,
      },
      {
        id: "investigations",
        title: "Investigations & Scans",
        type: "item",
        url: "/investigations",
        icon: icons.IconTestPipe,
        breadcrumbs: false,
      },
    ],
  },
  {
    id: "setting-pages",
    type: "group",
    children: [
      {
        id: "setting-pages",
        title: "Settings",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          {
            id: "bill-items",
            title: "IPD Bill Items",
            type: "item",
            url: "/bill-items",

            breadcrumbs: false,
          },
          {
            id: "opd-receipt-item",
            title: "OPD Bill Items",
            type: "item",
            url: "/opd-receipt-item-master",

            breadcrumbs: false,
          },
          {
            id: "drug-items",
            title: "Prescription Drug Master",
            type: "item",
            url: "/drug-items",

            breadcrumbs: false,
          },
          {
            id: "prescription-templates",
            title: "Prescription Templates",
            type: "item",
            url: "/prescription-templates",

            breadcrumbs: false,
          },
          {
            id: "investigation-item",
            title: "Investigation Master",
            type: "item",
            url: "/investigation-items",
            breadcrumbs: false,
          },
          {
            id: "investigation-templets",
            title: "Investigation Templates",
            type: "item",
            url: "/investigation-templets",
            breadcrumbs: false,
          },
          {
            id: "operation-types",
            title: "Operation Types",
            type: "item",
            url: "/operation-types",

            breadcrumbs: false,
          },
          {
            id: "expense-category-item",
            title: "Expenses Category",
            type: "item",
            url: "/expense-category-item-master",

            breadcrumbs: false,
          },
          {
            id: "employees",
            title: "Employees",
            type: "item",
            url: "/users",

            breadcrumbs: false,
          },
          {
            id: "external-staff",
            title: "External Staff",
            type: "item",
            url: "/external-staff",

            breadcrumbs: false,
          },
          // {
          //   id: "appointment-slot",
          //   title: "Appointment Slot",
          //   type: "item",
          //   url: "/appointment-slot",
          //   breadcrumbs: false,
          // },
          {
            id: "refferedBy",
            title: "Referal Doctors",
            type: "item",
            url: "/refferedBy",

            breadcrumbs: false,
          },
          {
            id: "additional-treatment-item",
            title: "Additional Treatments",
            type: "item",
            url: "/settings/additional-treatment",
            breadcrumbs: false,
          },
          {
            id: "general-settings",
            title: "General settings",
            type: "item",
            url: "/general-settings",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },

  {
    id: "report-pages",
    type: "group",
    children: [
      {
        id: "reports-pages",
        title: "Reports",
        type: "collapse",
        icon: icons.IconSettings,
        breadcrumbs: false,
        children: [
          // {
          //   id: "opd-reports",
          //   title: "OPD Billing",
          //   type: "item",
          //   //url: "/opd-reports",
          //   url: "/revenue-report",

          //   breadcrumbs: false,
          // },
          // {
          //   id: "investigation-reports",
          //   title: "Investigations",
          //   type: "item",
          //   url: "/investigation-reports",
          //   breadcrumbs: false,
          // },
          // {
          //   id: "ipd-reports",
          //   title: "IPD Billing",
          //   type: "item",
          //   url: "/ipd-reports",
          //   breadcrumbs: false,
          // },
          {
            id: "summary-report",
            title: "Summary Report",
            type: "item",
            url: "/summary-report",
            breadcrumbs: false,
          },
          {
            id: "medication-report",
            title: "Medication Report",
            type: "item",
            url: "/medication-report",
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default doctorMenuItems;
