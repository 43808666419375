import {
  Card,
  Divider,
  Typography,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Button,
  Skeleton,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material";

import BiotechIcon from "@mui/icons-material/Biotech";
import React, { useEffect, useState } from "react";
import useInvestigationItemsMasterService from "services/useInvestigationItemsMasterService";
import useInvestigationItemService from "services/useInvestigationItemService";
import { LoadingButton } from "@mui/lab";
import { convertDateToLocalReverse } from "utils/DateOperations";

const SuggestInvestigationsForm = ({
  onClose,
  onSave,
  appointmentId,
  patientId,
  investigationItems,
  fromAppointment,
}) => {
  const theme = useTheme();

  //Services
  const { getInvestigationMasterItemList } =
    useInvestigationItemsMasterService();
  const { addInvestigationItems } = useInvestigationItemService();

  //States
  const [minDate, setMinDate] = useState(convertDateToLocalReverse(new Date()));
  const [investigationItemMaster, setInvestigationItemMaster] = useState();
  const [checked, setChecked] = React.useState([0]);
  const [selectedInvestigationItems, setSelectedInvestigationItems] = useState(
    []
  );
  const [finalInvestigationList, setFinalInvestigationList] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [dateValues, setDateValues] = useState(
    convertDateToLocalReverse(new Date())
  );

  //Methods
  const handleDateChange = (id, date) => {
    setDateValues((prevDateValues) => ({
      ...prevDateValues,
      [id]: date,
    }));
  };

  const gatherInvestigationItemList = () => {
    getInvestigationMasterItemList(0)
      .then((response) => {
        //Function to compare both arrays and show only those objects of arr2 which are not present in arr1
        const arr1 = investigationItems.map((item) => item.name);
        const arr2 = response.data.investigationItemsMasters.filter(
          (item) => !arr1.includes(item.name)
        );
        setInvestigationItemMaster(arr2);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addInvestigations = () => {
    const investigationItemsWithDates = selectedInvestigationItems.map(
      (item) => {
        const createDate =
          dateValues[item.id] || convertDateToLocalReverse(new Date());
        return {
          ...item,
          createDate,
        };
      }
    );

    let data = {
      investigationItems: investigationItemsWithDates,
    };
    setSubmitting(true);

    addInvestigationItems(data)
      .then((response) => {
        console.log("response", response.data);
        setSubmitting(false);
        onSave();
        onClose();
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  const handleToggle = (value, index) => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectedInvestigationItems([
        ...selectedInvestigationItems,
        {
          id: value.id,
          appointmentId: appointmentId,
          patientId: patientId,
          name: value.name,
          description: value.description,
          type: value.type,
          labType: value.labType,
          amount: value.amount,
          slotTime: "",
        },
      ]);
    } else {
      newChecked.splice(currentIndex, 1);
      selectedInvestigationItems.splice(index, 1);
    }

    setChecked(newChecked);
  };

  //UseEffects
  useEffect(() => {
    gatherInvestigationItemList();
  }, []);

  const changeType = (singleRecord, type) => {
    // console.log("data", singleRecord, type);
    // const data = selectedInvestigationItems.find((ele) => {
    //   return ele.id == singleRecord.id;
    // });
    selectedInvestigationItems.forEach((ele) => {
      if (ele.id == singleRecord.id) {
        ele.type = type;
      }
    });

    if (investigationItemMaster) {
      investigationItemMaster?.forEach((ele) => {
        if (ele.id == singleRecord.id) {
          ele.type = type;
        }
      });
      setChangeCount(changeCount + 1);
      setInvestigationItemMaster(investigationItemMaster);
    }

    // if (data) {
    //   data.type = type;
    //   selectedInvestigationItems.splice(data.id, 1);
    //   setSelectedInvestigationItems([...selectedInvestigationItems, data]);
    // }
  };
  return (
    <>
      <Grid sx={{ padding: fromAppointment ? 2 : null }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ marginX: "auto", alignItems: "center" }}
        >
          <BiotechIcon fontSize="medium" color="primary" />
          <Typography variant="h4">Suggested Investigations</Typography>
        </Stack>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid
        container
        sx={{
          maxHeight: 600,
          overflow: "auto",
          padding: fromAppointment ? 2 : null,
        }}
      >
        <Grid item sm={12}>
          <Typography>Select the investigations you want to suggest</Typography>
        </Grid>
        {investigationItemMaster != undefined ? (
          <>
            <Grid item sm={12}>
              <List>
                {investigationItemMaster?.map((row, index) => {
                  const labelId = `checkbox-list-label-${row}`;
                  return (
                    <Grid container>
                      <Grid item sm={12} md={12}>
                        <Card
                          variant="outlined"
                          sx={{
                            margin: 0.5,
                          }}
                        >
                          <ListItem
                            key={index}
                            secondaryAction={
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant={
                                    row.type == 0 ? "contained" : "outlined"
                                  }
                                  onClick={() => {
                                    changeType(row, 0);
                                  }}
                                  disabled={checked.indexOf(row) == -1}
                                >
                                  Internal
                                </Button>
                                <Button
                                  variant={
                                    row.type == 1 ? "contained" : "outlined"
                                  }
                                  onClick={() => {
                                    changeType(row, 1);
                                  }}
                                  disabled={checked.indexOf(row) == -1}
                                >
                                  External
                                </Button>
                              </Stack>
                            }
                            disablePadding
                          >
                            <ListItemButton
                              role={undefined}
                              //onClick={handleToggle(row, index)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  onClick={handleToggle(row, index)}
                                  checked={checked.indexOf(row) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  <>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Typography variant="h5">
                                        {row.name}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight="light"
                                      >
                                        |
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        fontWeight="light"
                                      >
                                        {row.typeDisplay}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight="light"
                                      >
                                        |
                                      </Typography>

                                      <Typography
                                        color="secondary"
                                        variant="h6"
                                      >
                                        ₹ {row.amount}
                                      </Typography>
                                      {row.type === 0 &&
                                        checked.indexOf(row) !== -1 && (
                                          <TextField
                                            type="date"
                                            variant="standard"
                                            label="Date"
                                            value={
                                              dateValues[row.id] ||
                                              convertDateToLocalReverse(
                                                new Date()
                                              )
                                            }
                                            onChange={(e) =>
                                              handleDateChange(
                                                row.id,
                                                e.target.value
                                              )
                                            }
                                            InputProps={{
                                              inputProps: {
                                                min: minDate,
                                              },
                                            }}
                                          />
                                        )}
                                    </Stack>
                                  </>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </Card>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
            </Grid>
          </>
        ) : (
          <Grid item sm={12} sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={100} />
              <Skeleton variant="rounded" height={100} />
            </Stack>
          </Grid>
        )}
      </Grid>
      <Grid>
        <Divider sx={{ marginY: 2 }} />
      </Grid>
      <Grid>
        <LoadingButton
          variant="contained"
          onClick={() => {
            addInvestigations();
          }}
          sx={{ margin: 1 }}
          loading={submitting}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export default SuggestInvestigationsForm;
